import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../ThemeContext';
import { Button } from '../components/ui/Button';
import { Card } from '../components/ui/Card';
import { auth } from '../config/firebase';
import db from '../config/firestore';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

const AddStartupPage = () => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    logo: '',
    description: '',
    foundedYear: '',
    location: '',
    team: [{ name: '', role: '' }],
    metrics: [{ label: '', value: '' }],
    fundingHistory: [{ round: '', amount: '', date: '' }],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleArrayChange = (index, field, subfield, value) => {
    setFormData(prevState => {
      const newArray = [...prevState[field]];
      newArray[index] = { ...newArray[index], [subfield]: value };
      return { ...prevState, [field]: newArray };
    });
  };

  const addArrayItem = (field) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: [...prevState[field], field === 'team' ? { name: '', role: '' } : field === 'metrics' ? { label: '', value: '' } : { round: '', amount: '', date: '' }]
    }));
  };

  const removeArrayItem = (field, index) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: prevState[field].filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const user = auth.currentUser;

      if (!user) {
        throw new Error('User not authenticated');
      }

      const startupsRef = collection(db, 'startups');
      
      const docRef = await addDoc(startupsRef, {
        ...formData,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });

      console.log('Startup added successfully:', docRef.id);
      setSubmitMessage('Startup added successfully');
      setFormData({
        name: '',
        logo: '',
        description: '',
        foundedYear: '',
        location: '',
        team: [{ name: '', role: '' }],
        metrics: [{ label: '', value: '' }],
        fundingHistory: [{ round: '', amount: '', date: '' }],
      });
    } catch (error) {
      console.error('Error adding startup:', error);
      setSubmitMessage(`Error: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const inputStyle = `w-full p-2 border rounded-md ${theme.inputBgColor} ${theme.inputBorderColor} text-black`;

  return (
    <div className={`min-h-screen py-4 px-6 ${theme.bgColor}`}>
      <Card className="max-w-2xl mx-auto p-6">
        <h1 className={`text-2xl font-bold mb-6 ${theme.textColor}`}>Add Startup</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className={`block mb-1 font-medium ${theme.labelColor}`}>Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={inputStyle}
              required
            />
          </div>
          <div>
            <label htmlFor="logo" className={`block mb-1 font-medium ${theme.labelColor}`}>Logo URL</label>
            <input
              type="text"
              id="logo"
              name="logo"
              value={formData.logo}
              onChange={handleChange}
              className={inputStyle}
            />
          </div>
          <div>
            <label htmlFor="description" className={`block mb-1 font-medium ${theme.labelColor}`}>Description</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              className={`${inputStyle} h-24`}
              required
            />
          </div>
          <div>
            <label htmlFor="foundedYear" className={`block mb-1 font-medium ${theme.labelColor}`}>Founded Year</label>
            <input
              type="number"
              id="foundedYear"
              name="foundedYear"
              value={formData.foundedYear}
              onChange={handleChange}
              className={inputStyle}
              required
            />
          </div>
          <div>
            <label htmlFor="location" className={`block mb-1 font-medium ${theme.labelColor}`}>Location</label>
            <input
              type="text"
              id="location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              className={inputStyle}
              required
            />
          </div>
          <div>
            <label className={`block mb-1 font-medium ${theme.labelColor}`}>Team</label>
            {formData.team.map((member, index) => (
              <div key={index} className="flex mb-2 space-x-2">
                <input
                  type="text"
                  value={member.name}
                  onChange={(e) => handleArrayChange(index, 'team', 'name', e.target.value)}
                  className={`${inputStyle} flex-grow`}
                  placeholder="Name"
                />
                <input
                  type="text"
                  value={member.role}
                  onChange={(e) => handleArrayChange(index, 'team', 'role', e.target.value)}
                  className={`${inputStyle} flex-grow`}
                  placeholder="Role"
                />
                <button
                  type="button"
                  onClick={() => removeArrayItem('team', index)}
                  className="px-2 py-1 bg-red-500 text-white rounded"
                >
                  -
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addArrayItem('team')}
              className="mt-2 px-2 py-1 bg-green-500 text-white rounded"
            >
              + Add Team Member
            </button>
          </div>
          <div>
            <label className={`block mb-1 font-medium ${theme.labelColor}`}>Metrics</label>
            {formData.metrics.map((metric, index) => (
              <div key={index} className="flex mb-2 space-x-2">
                <input
                  type="text"
                  value={metric.label}
                  onChange={(e) => handleArrayChange(index, 'metrics', 'label', e.target.value)}
                  className={`${inputStyle} flex-grow`}
                  placeholder="Label"
                />
                <input
                  type="text"
                  value={metric.value}
                  onChange={(e) => handleArrayChange(index, 'metrics', 'value', e.target.value)}
                  className={`${inputStyle} flex-grow`}
                  placeholder="Value"
                />
                <button
                  type="button"
                  onClick={() => removeArrayItem('metrics', index)}
                  className="px-2 py-1 bg-red-500 text-white rounded"
                >
                  -
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addArrayItem('metrics')}
              className="mt-2 px-2 py-1 bg-green-500 text-white rounded"
            >
              + Add Metric
            </button>
          </div>
          <div>
            <label className={`block mb-1 font-medium ${theme.labelColor}`}>Funding History</label>
            {formData.fundingHistory.map((round, index) => (
              <div key={index} className="flex mb-2 space-x-2">
                <input
                  type="text"
                  value={round.round}
                  onChange={(e) => handleArrayChange(index, 'fundingHistory', 'round', e.target.value)}
                  className={`${inputStyle} flex-grow`}
                  placeholder="Round"
                />
                <input
                  type="text"
                  value={round.amount}
                  onChange={(e) => handleArrayChange(index, 'fundingHistory', 'amount', e.target.value)}
                  className={`${inputStyle} flex-grow`}
                  placeholder="Amount"
                />
                <input
                  type="text"
                  value={round.date}
                  onChange={(e) => handleArrayChange(index, 'fundingHistory', 'date', e.target.value)}
                  className={`${inputStyle} flex-grow`}
                  placeholder="Date"
                />
                <button
                  type="button"
                  onClick={() => removeArrayItem('fundingHistory', index)}
                  className="px-2 py-1 bg-red-500 text-white rounded"
                >
                  -
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addArrayItem('fundingHistory')}
              className="mt-2 px-2 py-1 bg-green-500 text-white rounded"
            >
              + Add Funding Round
            </button>
          </div>
          <Button
            type="submit"
            className="w-full mt-4"
            style={{
              backgroundColor: theme.primaryColor,
              color: 'white',
              padding: '0.5rem 1rem',
              fontSize: '1rem',
              fontWeight: 'bold',
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? t('common.submitting') : 'Add Startup'}
          </Button>
        </form>
        {submitMessage && (
          <p className={`mt-4 text-center ${submitMessage.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>
            {submitMessage}
          </p>
        )}
      </Card>
    </div>
  );
};

export default AddStartupPage;