import React, { useContext } from 'react';
import { ThemeContext } from '../ThemeContext';
import SteerUpOverview from '../components/lead-investors/SteerUpOverview';
import LeadInvestorProfile from '../components/lead-investors/LeadInvestorProfile';
import ValueProposition from '../components/lead-investors/ValueProposition';
import CommunityJourney from '../components/lead-investors/CommunityJourney';
import WhatsNextSection from '../components/lead-investors/WhatsNextSection';
import ScrollAnimation from '../components/ui/ScrollAnimation';

const LeadInvestorsPage = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div 
      className="w-full"
      style={{ backgroundColor: theme.appBackgroundColor }}
    >
      <div className="max-w-[1920px] mx-auto px-4 md:px-8 lg:px-12 xl:px-16 py-8">
        {/* Main Content - Paired Sections */}
        <div className="space-y-32 md:space-y-16">
          {/* Overview section */}
          <ScrollAnimation animation="zoom-out">
            <SteerUpOverview />
          </ScrollAnimation>

          {/* Lead Investor section */}
          <ScrollAnimation animation="zoom-out">
            <LeadInvestorProfile />
          </ScrollAnimation>

          {/* Value Proposition section */}
          <ScrollAnimation animation="zoom-out">
            <ValueProposition />
          </ScrollAnimation>

          {/* Community Journey section */}
          <ScrollAnimation animation="zoom-out">
            <CommunityJourney />
          </ScrollAnimation>

          {/* What's Next section without animation wrapper */}
          <WhatsNextSection />
        </div>
      </div>
    </div>
  );
};

export default LeadInvestorsPage;
