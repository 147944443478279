import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../ThemeContext';

const InvestorCard = ({ title, points, theme }) => (
  <div
    className="flex flex-col p-4 md:p-6 lg:p-8 rounded-xl h-full border border-opacity-25"
    style={{
      backgroundColor: 'transparent',
      borderColor: theme.primaryColor
    }}
  >
    <h3
      className="text-2xl md:text-xl lg:text-2xl font-bold text-center md:text-left mb-4"
      style={{ color: theme.primaryColor }}
    >
      {title}
    </h3>
    <div className="space-y-4">
      {points.map((point, index) => (
        <div
          key={index}
          className="flex items-start"
        >
          <span
            className="w-1.5 h-1.5 rounded-full mr-3 mt-2 flex-shrink-0"
            style={{ backgroundColor: theme.primaryColor }}
          />
          <p className="text-base md:text-sm lg:text-base leading-relaxed" style={{ color: theme.textColor }}>{point}</p>
        </div>
      ))}
    </div>
  </div>
);

const LeadInvestorProfile = () => {
  const { t } = useTranslation('businessDev');
  const { theme } = useContext(ThemeContext);

  return (
    <div
      className="w-full rounded-xl p-4 md:p-8 lg:p-12"
    >
      <h2
        className="text-2xl md:text-3xl font-bold mb-8 text-center md:text-left"
        style={{ color: theme.textColor }}
      >
        {t('leadInvestor.title')}
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
        <InvestorCard
          title={t('leadInvestor.skinInTheGame.title')}
          points={Object.values(t('leadInvestor.skinInTheGame.points', { returnObjects: true }))}
          theme={theme}
        />

        <InvestorCard
          title={t('leadInvestor.dealFlow.title')}
          points={Object.values(t('leadInvestor.dealFlow.points', { returnObjects: true }))}
          theme={theme}
        />

        <InvestorCard
          title={t('leadInvestor.dueDiligence.title')}
          points={Object.values(t('leadInvestor.dueDiligence.points', { returnObjects: true }))}
          theme={theme}
        />
      </div>
    </div>
  );
};

export default LeadInvestorProfile;
