import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../ThemeContext';
import InvestmentTeamMemberCard from './InvestmentTeamMemberCard';

const InvestorCard = ({ name, taglineKey, aboutKey, domainAuthority, imagePath, theme, teamMembers, investmentPartners, status }) => {
  const { t } = useTranslation('businessDev');

  return (
    <div className="w-full">
      <div
        className="rounded-xl overflow-hidden p-3 md:p-5 relative md:p-8"
        style={{
          border: `1px solid ${theme.primaryColor}80`
        }}
      >
        {/* Status Badge */}
        {status && (
          <div
            className="absolute top-3 right-3 px-2 py-1 rounded-full text-xs font-medium"
            style={{
              backgroundColor: theme.primaryColor,
              color: '#fff'
            }}
          >
            {status}
          </div>
        )}

        <div className="flex flex-col gap-4 md:gap-6">
          {/* Header with logo and title */}
          <div className="flex items-start gap-3">
            {imagePath && (
              <img
                src={imagePath}
                alt={name}
                className="w-16 h-16 rounded-lg object-cover flex-shrink-0"
              />
            )}
            <div className="flex-grow min-w-0">
              <h3 className="text-xl font-bold text-white mb-1">{t(`currentLeadInvestors.investors.${name}.name`)}</h3>
              <p className="text-gray-400 text-sm leading-relaxed">{t(`currentLeadInvestors.investors.${taglineKey}`)}</p>
            </div>
          </div>

          {/* About section */}
          <div className="space-y-1.5">
            <h4 className="text-white text-base font-bold">{t('currentLeadInvestors.about')}</h4>
            <p className="text-gray-400 text-sm leading-relaxed">{t(`currentLeadInvestors.investors.${aboutKey}`)}</p>
          </div>

          {/* Domain Authority section */}
          {domainAuthority && domainAuthority.length > 0 && (
            <div className="space-y-2">
              <h4 className="text-white text-base font-bold">{t('currentLeadInvestors.domainAuthority')}</h4>
              <div className="flex flex-wrap gap-1.5 md:gap-2">
                {domainAuthority.map((item, index) => (
                  <span
                    key={index}
                    className="px-2 py-0.5 md:px-3 md:py-1 rounded-full text-xs md:text-sm bg-[#30363D] text-white font-medium"
                  >
                    {t(`currentLeadInvestors.investors.${name}.domainAuthority.${index}`)}
                  </span>
                ))}
              </div>
            </div>
          )}

          {/* Investment Team section */}
          {teamMembers && teamMembers.length > 0 && (
            <div className="space-y-2">
              <h4 className="text-white text-base font-bold">{t('currentLeadInvestors.status.investmentTeam')}</h4>
              <div className="flex flex-col gap-2">
                {teamMembers.map((member, index) => (
                  <InvestmentTeamMemberCard
                    key={index}
                    {...member}
                    theme={theme}
                  />
                ))}
              </div>
            </div>
          )}

          {/* Investment Partners section */}
          {investmentPartners && investmentPartners.length > 0 && (
            <div className="space-y-2">
              <h4 className="text-white text-base font-bold">{t('currentLeadInvestors.status.investmentPartners')}</h4>
              <div className="grid grid-cols-2 gap-2">
                {investmentPartners.map((partner, index) => (
                  <div
                    key={index}
                    className="rounded-lg flex items-center justify-center h-16"
                    style={{
                      border: `1px solid #9ca3af40`
                    }}
                  >
                    <div className="w-[85%] h-[85%] flex items-center justify-center">
                      <img
                        src={partner}
                        alt={`Investment Partner ${index + 1}`}
                        className="max-w-full max-h-full object-contain"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const CurrentLeadInvestors = () => {
  const { t } = useTranslation('businessDev');
  const { theme } = useContext(ThemeContext);

  const indieAsylumTeam = [
    {
      name: t('currentLeadInvestors.investors.indieAsylum.team.pascal.name'),
      position: t('currentLeadInvestors.investors.indieAsylum.team.pascal.position'),
      investmentYears: t('currentLeadInvestors.investors.indieAsylum.team.pascal.investmentYears'),
      dealsCount: t('currentLeadInvestors.investors.indieAsylum.team.pascal.dealsCount'),
      profilePic: "pascal-nataf-profile-picture.jpeg",
      linkedinUrl: "https://www.linkedin.com/in/pascalnataf/"
    },
    {
      name: t('currentLeadInvestors.investors.indieAsylum.team.christopher.name'),
      position: t('currentLeadInvestors.investors.indieAsylum.team.christopher.position'),
      investmentYears: t('currentLeadInvestors.investors.indieAsylum.team.christopher.investmentYears'),
      dealsCount: t('currentLeadInvestors.investors.indieAsylum.team.christopher.dealsCount'),
      profilePic: "christopher-chancey-profile-picture.jpeg",
      linkedinUrl: "https://www.linkedin.com/in/christopher-chancey-b0524098"
    },
    {
      name: t('currentLeadInvestors.investors.indieAsylum.team.kim.name'),
      position: t('currentLeadInvestors.investors.indieAsylum.team.kim.position'),
      investmentYears: t('currentLeadInvestors.investors.indieAsylum.team.kim.investmentYears'),
      dealsCount: t('currentLeadInvestors.investors.indieAsylum.team.kim.dealsCount'),
      profilePic: "kim-berthiaume-profile-picture.jpeg",
      linkedinUrl: "https://www.linkedin.com/in/kimberthiaume"
    }
  ];

  const indieAsylumPartners = [
    "/images/business-dev/ubisoft-logo.png",
    "/images/business-dev/fonds-des-medias-logo.png",
    "/images/business-dev/quebec-tech-logo.png",
    "/images/business-dev/investissements-quebec-logo.png"
  ];

  return (
    <div className="rounded-xl md:p-5">
      <h2
        className="text-xl md:text-2xl font-bold mb-4 text-center"
        style={{ color: theme.primaryColor }}
      >
        {t('whatsNext.leadInvestors')}
      </h2>

      <div className="flex flex-col md:grid md:grid-cols-1 md:gap-10">
        <InvestorCard
          name="indieAsylum"
          taglineKey="indieAsylum.tagline"
          aboutKey="indieAsylum.about"
          imagePath="/images/business-dev/indie-asylum-logo.jpg"
          domainAuthority={[0, 1, 2, 3]}
          theme={theme}
          teamMembers={indieAsylumTeam}
          investmentPartners={indieAsylumPartners}
          status="2025 Pilot"
        />

        {/* 
          <InvestorCard
            name="desjardins"
            taglineKey="desjardins.tagline"
            aboutKey="desjardins.about"
            imagePath="/images/business-dev/desjardins-logo.png"
            domainAuthority={[0, 1, 2, 3, 4]}
            theme={theme}
          /> */}
      </div>
    </div>
  );
};

export default CurrentLeadInvestors;
