import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-CA',
    supportedLngs: ['en-CA', 'fr-CA'],
    debug: true,
    ns: ['common', 'auth', 'pages', 'components', 'features', 'financingJourney'],
    defaultNS: 'common',
    fallbackNS: 'common',
    load: 'currentOnly',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    react: {
      useSuspense: true,
      bindI18n: 'languageChanged loaded',
      bindI18nStore: 'added removed',
      nsMode: 'default',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'span'],
    },
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
  });

// Handle language change events
i18n.on('languageChanged', (lng) => {
  document.documentElement.lang = lng;
  // Force update dayjs locale if it's being used
  if (window.dayjs) {
    window.dayjs.locale(lng);
  }
});

export default i18n;
