import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../ThemeContext';
import { Button } from '../components/ui/Button';
import { Card } from '../components/ui/Card';
import { auth } from '../config/firebase';
import db from '../config/firestore';
import { collection, addDoc, getDocs, serverTimestamp } from 'firebase/firestore';

const AddStartupSelectionPage = () => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    experts: [],
    startups: [],
    fundingGoal: '',
    currentFunding: '',
    backers: '',
    remainingDays: '',
    additionalFunding: [{ source: { name: '' }, type: '', amount: '' }]
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [leadInvestors, setLeadInvestors] = useState([]);
  const [availableStartups, setAvailableStartups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const leadInvestorsRef = collection(db, 'lead_investors');
        const startupsRef = collection(db, 'startups');

        const [investorsSnapshot, startupsSnapshot] = await Promise.all([
          getDocs(leadInvestorsRef),
          getDocs(startupsRef)
        ]);

        const investors = investorsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const startups = startupsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        setLeadInvestors(investors);
        setAvailableStartups(startups);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("An error occurred while loading data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleExpertChange = (e) => {
    const selectedExperts = Array.from(e.target.selectedOptions, option => option.value);
    setFormData(prevState => ({
      ...prevState,
      experts: selectedExperts
    }));
  };

  const handleStartupChange = (e) => {
    const selectedStartups = Array.from(e.target.selectedOptions, option => option.value);
    setFormData(prevState => ({
      ...prevState,
      startups: selectedStartups
    }));
  };

  const handleArrayChange = (index, field, subfield, value) => {
    setFormData(prevState => {
      const newArray = [...prevState[field]];
      if (subfield) {
        newArray[index] = { ...newArray[index], [subfield]: value };
      } else {
        newArray[index] = value;
      }
      return { ...prevState, [field]: newArray };
    });
  };

  const addArrayItem = (field) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: [...prevState[field], { source: { name: '' }, type: '', amount: '' }]
    }));
  };

  const removeArrayItem = (field, index) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: prevState[field].filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const user = auth.currentUser;

      if (!user) {
        throw new Error('User not authenticated');
      }

      if (formData.experts.length === 0 || formData.startups.length === 0) {
        throw new Error('Please select at least one expert and one startup.');
      }

      const startupSelectionsRef = collection(db, 'startup_selections');
      
      const docRef = await addDoc(startupSelectionsRef, {
        ...formData,
        fundingGoal: Number(formData.fundingGoal),
        currentFunding: Number(formData.currentFunding),
        backers: Number(formData.backers),
        remainingDays: Number(formData.remainingDays),
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });

      console.log('Startup selection added successfully:', docRef.id);
      setSubmitMessage('Startup selection added successfully');
      setFormData({
        name: '',
        experts: [],
        startups: [],
        fundingGoal: '',
        currentFunding: '',
        backers: '',
        remainingDays: '',
        additionalFunding: [{ source: { name: '' }, type: '', amount: '' }]
      });
    } catch (error) {
      console.error('Error adding startup selection:', error);
      setSubmitMessage(`Error: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const inputStyle = `w-full p-2 border rounded-md ${theme.inputBgColor} ${theme.inputBorderColor} text-black`;

  if (isLoading) {
    return (
      <div className={`min-h-screen py-4 px-6 ${theme.bgColor} flex items-center justify-center`}>
        <p className={`text-xl ${theme.textColor}`}>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`min-h-screen py-4 px-6 ${theme.bgColor} flex items-center justify-center`}>
        <p className={`text-xl text-red-500`}>{error}</p>
      </div>
    );
  }

  return (
    <div className={`min-h-screen py-4 px-6 ${theme.bgColor}`}>
      <Card className="max-w-2xl mx-auto p-6">
        <h1 className={`text-2xl font-bold mb-6 ${theme.textColor}`}>Add Startup Selection</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className={`block mb-1 font-medium ${theme.labelColor}`}>Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={inputStyle}
              required
            />
          </div>

          <div>
            <label className={`block mb-1 font-medium ${theme.labelColor}`}>Experts</label>
            <select
              multiple
              value={formData.experts}
              onChange={handleExpertChange}
              className={`${inputStyle} h-32`}
            >
              {leadInvestors.map(investor => (
                <option key={investor.id} value={investor.id}>{investor.name}</option>
              ))}
            </select>
          </div>

          <div>
            <label className={`block mb-1 font-medium ${theme.labelColor}`}>Startups</label>
            <select
              multiple
              value={formData.startups}
              onChange={handleStartupChange}
              className={`${inputStyle} h-32`}
            >
              {availableStartups.map(startup => (
                <option key={startup.id} value={startup.id}>{startup.name}</option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="fundingGoal" className={`block mb-1 font-medium ${theme.labelColor}`}>Funding Goal</label>
            <input
              type="number"
              id="fundingGoal"
              name="fundingGoal"
              value={formData.fundingGoal}
              onChange={handleChange}
              className={inputStyle}
              required
            />
          </div>

          <div>
            <label htmlFor="currentFunding" className={`block mb-1 font-medium ${theme.labelColor}`}>Current Funding</label>
            <input
              type="number"
              id="currentFunding"
              name="currentFunding"
              value={formData.currentFunding}
              onChange={handleChange}
              className={inputStyle}
              required
            />
          </div>

          <div>
            <label htmlFor="backers" className={`block mb-1 font-medium ${theme.labelColor}`}>Backers</label>
            <input
              type="number"
              id="backers"
              name="backers"
              value={formData.backers}
              onChange={handleChange}
              className={inputStyle}
              required
            />
          </div>

          <div>
            <label htmlFor="remainingDays" className={`block mb-1 font-medium ${theme.labelColor}`}>Remaining Days</label>
            <input
              type="number"
              id="remainingDays"
              name="remainingDays"
              value={formData.remainingDays}
              onChange={handleChange}
              className={inputStyle}
              required
            />
          </div>

          <div>
            <label className={`block mb-1 font-medium ${theme.labelColor}`}>Additional Funding</label>
            {formData.additionalFunding.map((funding, index) => (
              <div key={index} className="flex flex-col mb-2 space-y-2">
                <input
                  type="text"
                  value={funding.source.name}
                  onChange={(e) => handleArrayChange(index, 'additionalFunding', ['source', 'name'], e.target.value)}
                  className={inputStyle}
                  placeholder="Source Name"
                />
                <input
                  type="text"
                  value={funding.type}
                  onChange={(e) => handleArrayChange(index, 'additionalFunding', 'type', e.target.value)}
                  className={inputStyle}
                  placeholder="Type"
                />
                <input
                  type="number"
                  value={funding.amount}
                  onChange={(e) => handleArrayChange(index, 'additionalFunding', 'amount', e.target.value)}
                  className={inputStyle}
                  placeholder="Amount"
                />
                <button
                  type="button"
                  onClick={() => removeArrayItem('additionalFunding', index)}
                  className="px-2 py-1 bg-red-500 text-white rounded"
                >
                  Remove Additional Funding
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addArrayItem('additionalFunding')}
              className="mt-2 px-2 py-1 bg-green-500 text-white rounded"
            >
              Add Additional Funding
            </button>
          </div>

          <Button
            type="submit"
            className="w-full mt-4"
            style={{
              backgroundColor: theme.primaryColor,
              color: 'white',
              padding: '0.5rem 1rem',
              fontSize: '1rem',
              fontWeight: 'bold',
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? t('common.submitting') : 'Add Startup Selection'}
          </Button>
        </form>
        {submitMessage && (
          <p className={`mt-4 text-center ${submitMessage.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>
            {submitMessage}
          </p>
        )}
      </Card>
    </div>
  );
};

export default AddStartupSelectionPage;