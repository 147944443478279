import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../ThemeContext';
import {
  ChartBarIcon,
  MegaphoneIcon,
  BanknotesIcon,
  ArrowLongRightIcon,
  ArrowLongDownIcon
} from '@heroicons/react/24/outline';

const JourneyStep = ({ icon: Icon, title, description, metric, theme }) => (
  <div
    className="flex flex-col items-center p-2 lg:p-4 rounded-xl w-full md:w-1/3"
    style={{ backgroundColor: theme.cardBgColor, height: "280px" }}
  >
    {/* Icon Section */}
    <div className="rounded-full p-2 mb-4" style={{ backgroundColor: theme.primaryColor + '20' }}>
      <Icon className="h-6 w-6 md:h-8 md:w-8" style={{ color: theme.primaryColor }} />
    </div>

    {/* Title Section */}
    <h3
      className="text-lg md:text-xl font-bold mb-4 text-center h-[28px] flex items-center"
      style={{ color: theme.textColor }}
    >
      {title}
    </h3>

    {/* Description Section */}
    <div className="h-[100px] flex">
      <p className="text-sm leading-relaxed text-center" style={{ color: theme.textColor + '99' }}>
        {description}
      </p>
    </div>

    {/* Metric Section */}
    <div className="h-[48px] flex">
      <p className="text-lg md:text-lg font-semibold text-center" style={{ color: theme.primaryColor }}>
        {metric}
      </p>
    </div>
  </div>
);

const PricingCard = ({ label, price, subtext, theme, pill, borderColor }) => {
  const renderPrice = () => {
    if (price === '% to be announced') {
      return (
        <span className="text-2xl md:text-3xl font-bold">
          %{' '}
          <span className="text-base md:text-lg opacity-75">
            to be announced
          </span>
        </span>
      );
    }
    return <span className="text-2xl md:text-3xl font-bold">{price}</span>;
  };

  return (
    <div
      className="flex flex-col items-center p-4 md:p-6 lg:p-8 rounded-xl w-full md:w-[300px] relative"
      style={{
        backgroundColor: theme.cardBgColor,
        borderLeft: `4px solid ${borderColor}40`
      }}
    >
      {pill && (
        <div
          className="absolute top-2 right-2 px-3 py-1 rounded-full text-xs font-medium"
          style={{ backgroundColor: borderColor + '20', color: borderColor }}
        >
          {pill}
        </div>
      )}
      <h3 className="text-lg md:text-xl mb-3 md:mb-4" style={{ color: borderColor }}>
        {label}
      </h3>
      <p className="text-center" style={{ color: theme.textColor }}>
        {renderPrice()}
        <br />
        <span className="text-sm md:text-base leading-relaxed">{subtext}</span>
      </p>
    </div>
  );
};

const Arrow = ({ direction = 'right', theme, isFinal = false }) => {
  const Icon = direction === 'right' ? ArrowLongRightIcon : ArrowLongDownIcon;
  return (
    <div className={`flex justify-center items-center ${direction === 'right' ? 'hidden md:flex w-8' : `md:hidden ${isFinal ? 'h-6 md:h-8 my-2' : 'h-4 my-1'}`}`}>
      <Icon
        className={`${isFinal ? 'h-6 w-6 md:h-8 md:w-8' : 'h-4 w-4 md:h-5 md:w-5'}`}
        style={{ color: theme.primaryColor }}
      />
    </div>
  );
};

const CommunityJourney = () => {
  const { t } = useTranslation('businessDev');
  const { theme } = useContext(ThemeContext);

  const journeySteps = [
    {
      icon: ChartBarIcon,
      title: t('communitySuccess.journey.validation.title'),
      description: t('communitySuccess.journey.validation.description'),
      metric: t('communitySuccess.journey.validation.metric')
    },
    {
      icon: MegaphoneIcon,
      title: t('communitySuccess.journey.amplification.title'),
      description: t('communitySuccess.journey.amplification.description'),
      metric: t('communitySuccess.journey.amplification.metric')
    },
    {
      icon: BanknotesIcon,
      title: t('communitySuccess.journey.conversion.title'),
      description: t('communitySuccess.journey.conversion.description'),
      metric: t('communitySuccess.journey.conversion.metric')
    }
  ];

  return (
    <div className="p-4 md:p-8 xl:p-16">
      <h2
        className="text-xl md:text-3xl font-bold mb-8 md:mb-12 text-center"
        style={{ color: theme.textColor }}
      >
        {t('communitySuccess.title')}
      </h2>

      {/* Journey Steps */}
      <div className="flex flex-col md:flex-row items-center justify-center gap-4 mb-8 md:mb-12">
        {journeySteps.map((step, index) => (
          <React.Fragment key={index}>
            <JourneyStep {...step} theme={theme} />
            {index < journeySteps.length - 1 && (
              <>
                <Arrow direction="right" theme={theme} />
                <Arrow direction="down" theme={theme} />
              </>
            )}
          </React.Fragment>
        ))}
      </div>

      {/* Success to Pricing Transition */}
      <div className="flex justify-center mb-8 md:mb-12">
        <Arrow direction="down" theme={theme} isFinal={true} />
      </div>

      {/* Pricing Section */}
      <div>
        <h3
          className="text-xl md:text-3xl font-bold mb-8 md:mb-12 text-center"
          style={{ color: theme.textColor }}
        >
          {t('pricing.title')}
        </h3>
        <div className="flex flex-col md:flex-row justify-center items-center gap-4 md:gap-6 lg:gap-8">
          <PricingCard
            label={t('pricing.labels.leadInvestors')}
            price="$5,000"
            subtext={t('pricing.points.2').split('\n')[1]}
            theme={theme}
            borderColor={theme.primaryColor}
          />
          <PricingCard
            label={t('pricing.labels.startups')}
            price="8.5%"
            subtext={t('pricing.points.1').split('\n')[1]}
            theme={theme}
            pill="Equity"
            borderColor={theme.primaryColor}
          />
          <PricingCard
            label={t('pricing.labels.startups')}
            price="% to be announced"
            subtext={t('pricing.points.3').split('\n')[1]}
            theme={theme}
            pill="Debt"
            borderColor={theme.thirdColor}
          />
        </div>
      </div>
    </div>
  );
};

export default CommunityJourney;
