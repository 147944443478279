import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../ThemeContext';
import IPhoneMockup from '../ui/IPhoneMockup';

const SteerUpOverview = () => {
  const { t } = useTranslation('businessDev');
  const { theme } = useContext(ThemeContext);

  return (
    <div className="w-full mb-16 rounded-xl p-3 md:p-8">
      <div className="grid md:grid-cols-12 gap-8">
        <div className="md:col-span-8">
          <h2
            className="text-3xl md:text-4xl font-bold mb-8 w-full text-center md:text-left relative"
            style={{ color: theme.textColor }}
          >
            <span className="relative inline-block">
              {t('steerUpOverview.skinInTheGame')}
              <svg
                className="absolute -bottom-1 left-0 w-full"
                viewBox="0 0 200 24"
                preserveAspectRatio="none"
                style={{ height: '0.7rem' }}
              >
                <path
                  d="M3,12 Q50,16 100,12 T197,12"
                  fill="none"
                  stroke={theme.primaryColor}
                  strokeWidth="6"
                  strokeLinecap="round"
                  style={{
                    transform: 'rotate(-0.5deg)',
                    transformOrigin: 'center'
                  }}
                />
              </svg>
            </span>
            {' '}{t('steerUpOverview.crowdfunding')}
          </h2>
          <div className="space-y-6">
            <p
              className="text-base md:text-xl text-center md:text-left"
              style={{ color: theme.textColor }}
            >
              {t('steerUpOverview.description.1')}
            </p>
            <p
              className="text-base md:text-xl text-center md:text-left"
              style={{ color: theme.textColor }}
            >
              {t('steerUpOverview.description.2')}
            </p>
          </div>
        </div>
        <div className="w-full md:col-span-4 flex justify-center items-center">
          <div className="w-[240px] md:w-[240px]">
            <IPhoneMockup
              screenshotSrc="images/business-dev/equity-selection.png"
              width={240}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SteerUpOverview;
