import React, { useContext } from 'react';
import { ThemeContext } from '../../../ThemeContext';

const NumberQuestion = ({ title, question, value, onChange, onEnterPress }) => {
  const { theme } = useContext(ThemeContext);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && value) {
      onEnterPress();
    }
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold mb-4">{title}</h2>
      <div>
        <label className="block text-sm font-medium mb-2">
          {question}
        </label>
        <input
          type="number"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onKeyPress={handleKeyPress}
          className="w-full p-3 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2"
          style={{ '--tw-ring-color': theme.primaryColor }}
        />
      </div>
    </div>
  );
};

export default NumberQuestion;
