import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Search } from 'lucide-react';
import StartupsSelectionCard from '../components/StartupsSelectionCard';
import { ThemeContext } from '../ThemeContext';
import { useTranslation } from 'react-i18next';
import { auth } from '../config/firebase';
import { onAuthStateChanged, getIdTokenResult } from 'firebase/auth';

const ExplorePage = () => {
  const { theme } = useContext(ThemeContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilter, setActiveFilter] = useState('All');
  const { t } = useTranslation(['pages', 'common']);
  const [startupSelections, setStartupSelections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const filters = ['All', 'Tech', 'Health', 'Finance', 'Green'];

  useEffect(() => {
    console.log("Setting up auth state listener...");
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      console.log("Auth state changed:", currentUser ? "User logged in" : "User logged out");
      if (currentUser) {
        setUser(currentUser);
        console.log("User authenticated:", currentUser.uid);
        try {
          const idTokenResult = await getIdTokenResult(currentUser, true);
          console.log("User claims:", idTokenResult.claims);
          setIsAdmin(idTokenResult.claims.startup_admin === true);
          fetchStartupSelections();
        } catch (error) {
          console.error("Error getting ID token result:", error);
          setError(`Error getting user claims: ${error.message}`);
        }
      } else {
        setUser(null);
        setIsAdmin(false);
        setIsLoading(false);
        setError("Please log in to view startup selections.");
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchStartupSelections = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Simulating API call
      const mockSelections = [
        { id: 1, name: 'Startup A', category: 'Tech', leadInvestor: 'Investor X' },
        { id: 2, name: 'Startup B', category: 'Health', leadInvestor: 'Investor Y' },
        { id: 3, name: 'Startup C', category: 'Finance', leadInvestor: 'Investor Z' },
      ];
      setStartupSelections(mockSelections);
    } catch (err) {
      console.error("Error fetching startup selections:", err);
      setError(`An error occurred while loading startup selections: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleManualFetch = async () => {
    if (user) {
      fetchStartupSelections();
    } else {
      setError("Please log in to fetch startup selections.");
    }
  };

  const filteredSelections = startupSelections.filter(selection =>
    selection.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (activeFilter === 'All' || selection.category === activeFilter)
  );

  if (isLoading) {
    return (
      <div className={`min-h-screen py-4 px-6 ${theme.bgColor} flex items-center justify-center`}>
        <p className={`text-xl ${theme.textColor}`}>{t('common:loading')}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen p-4 pt-8">
      <h1 className="text-3xl font-bold mb-6" style={{ color: theme.textColor }}>{t('pages:explore.title')}</h1>
      
      {error && (
        <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
          <p>{error}</p>
          <p>User authenticated: {user ? 'Yes' : 'No'}</p>
          <p>User ID: {user ? user.uid : 'N/A'}</p>
        </div>
      )}

      <button
        onClick={handleManualFetch}
        className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Manually Fetch Startup Selections
      </button>
      
      <div className="mb-6 relative">
        <input
          type="text"
          placeholder={t('pages:explore.searchPlaceholder')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 pl-12 rounded-lg bg-gray-700 text-white text-lg"
        />
        <Search className="absolute left-4 top-3.5 w-6 h-6" style={{ color: theme.textColor }} />
      </div>

      <div className="mb-8 flex flex-wrap gap-3">
        {filters.map((filter) => (
          <button
            key={filter}
            onClick={() => setActiveFilter(filter)}
            className={`px-4 py-2 rounded-full text-base ${activeFilter === filter ? 'bg-primary' : 'bg-gray-700'}`}
            style={{ color: theme.textColor, backgroundColor: activeFilter === filter ? theme.primaryColor : '' }}
          >
            {t(`pages:explore.filters.${filter.toLowerCase()}`)}
          </button>
        ))}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredSelections.map((selection) => (
          <StartupsSelectionCard 
            key={selection.id}
            {...selection}
            leadInvestor={selection.leadInvestor}
          />
        ))}
      </div>

      {filteredSelections.length === 0 && (
        <p className={`text-center text-xl ${theme.textColor}`}>
          {t('pages:explore.noSelections', { count: startupSelections.length })}
        </p>
      )}

      <div className="mt-8 flex justify-between items-center">
        <Link to="/dashboard" className="inline-block text-base font-bold" style={{ color: theme.secondaryColor }}>
          {t('pages:explore.backToDashboard')}
        </Link>
        {isAdmin && (
          <Link to="/admin" className="inline-block text-base font-bold px-4 py-2 rounded" style={{ backgroundColor: theme.primaryColor, color: theme.textColor }}>
            {t('pages:explore.adminPage')}
          </Link>
        )}
      </div>
    </div>
  );
};

export default ExplorePage;
