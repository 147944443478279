import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../ThemeContext';

const AmplifierVisual = () => {
  const { t } = useTranslation('businessDev');
  const { theme } = useContext(ThemeContext);
  
  return (
    <div className="w-full">
      <svg className="w-full transform scale-140 md:scale-100" viewBox="0 0 600 300" preserveAspectRatio="xMidYMid meet">
        {/* Upper Input Stream (Lead Investment) */}
        <rect
          x="50"
          y="60"
          width="250"
          height="60"
          fill={theme.primaryColor}
          rx="4"
        />
        <text
          x="175"
          y="90"
          fill="#FFFFFF"
          textAnchor="middle"
          dominantBaseline="middle"
          className="text-base md:text-lg font-medium"
        >
          {t('valueProposition.inputs.leadMoney')}
        </text>

        {/* Lower Input Stream (Crowd Money) */}
        <rect
          x="50"
          y="180"
          width="250"
          height="60"
          fill={theme.secondaryColor}
          rx="4"
        />
        <text
          x="175"
          y="210"
          fill="#FFFFFF"
          textAnchor="middle"
          dominantBaseline="middle"
          className="text-base md:text-lg font-medium"
        >
          {t('valueProposition.inputs.crowdMoney')}
        </text>

        {/* Amplifier Triangle */}
        <path
          d="M 300,50 L 300,250 L 500,150 Z"
          fill="none"
          stroke="url(#triangleGradient)"
          strokeWidth="8"
        />
        <defs>
          {/* Stroke gradient for triangle */}
          <linearGradient id="triangleGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: '#FFFFFF' }} />
            <stop offset="60%" style={{ stopColor: theme.primaryColor + '80' }} />
            <stop offset="100%" style={{ stopColor: theme.primaryColor }} />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default AmplifierVisual;
