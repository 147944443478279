import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { calculateFinancingJourney } from '../utils/financingJourneyCalculator';

import Navigation from '../components/financing-journey/Navigation';
import StageQuestion from '../components/financing-journey/questions/StageQuestion';
import NumberQuestion from '../components/financing-journey/questions/NumberQuestion';
import SelectQuestion from '../components/financing-journey/questions/SelectQuestion';
import Results from '../components/financing-journey/Results';
import ProgressBar from '../components/financing-journey/ProgressBar';

const growthGoals = ['moonshot', 'big', 'solid', 'steady'];
const timeHorizons = ['short', 'medium', 'long'];
const industries = [
  'Information Technology',
  'Software Development',
  'Healthcare',
  'Fintech',
  'Education',
  'Clean Energy',
  'Biotech',
  'Hardware Manufacturing',
  'Industrial Manufacturing',
  'Real Estate',
  'Transportation'
];

const TOTAL_STEPS = 8; // Total number of questions before results

const FinancingJourneyPage = () => {
  const { t } = useTranslation(['financingJourney']);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    stage: '',
    industry: '',
    fundingNeeded: '',
    existingFunding: null,
    opportunityCost: '',
    growthGoal: '',
    timeHorizon: '',
    ownershipStake: ''
  });
  const [journey, setJourney] = useState(null);

  const isCapitalIntensiveIndustry = (industry) => {
    return t('industry.capitalIntensive', { returnObjects: true }).includes(industry);
  };

  const getFundingRanges = () => {
    const type = isCapitalIntensiveIndustry(formData.industry) ? 'capitalIntensive' : 'general';
    return t(`fundingNeeded.ranges.${type}.${formData.stage}`, { returnObjects: true });
  };

  const handleStageSelect = (stage) => {
    setFormData(prev => ({
      ...prev,
      stage,
      timeInOperation: stage === 'ideation' ? 0 : 
                      stage === 'marketTested' ? 0.5 : 
                      stage === 'upAndRunning' ? 1 : 2,
      hasRevenue: stage === 'upAndRunning' || stage === 'growthReady',
      productStatus: stage === 'ideation' ? 'concept' :
                    stage === 'marketTested' ? 'prototype' :
                    'launched'
    }));
    setStep(prev => prev + 1);
  };

  const handleIndustrySelect = (industry) => {
    setFormData(prev => ({ ...prev, industry }));
    setStep(prev => prev + 1);
  };

  const handleExistingFundingSelect = (value) => {
    setFormData(prev => ({ ...prev, existingFunding: value }));
    setStep(prev => prev + 1);
  };

  const handleGrowthGoalSelect = (goal) => {
    setFormData(prev => ({ ...prev, growthGoal: goal }));
    setStep(prev => prev + 1);
  };

  const handleTimeHorizonSelect = (horizon) => {
    setFormData(prev => ({ ...prev, timeHorizon: horizon }));
    setStep(prev => prev + 1);
  };

  const mapTimeHorizonToYears = (horizon) => {
    switch (horizon) {
      case 'short': return 3;
      case 'medium': return 5;
      case 'long': return 7;
      default: return 5;
    }
  };

  const mapGrowthGoalToRates = (goal) => {
    switch (goal) {
      case 'moonshot': return { growth: 0.15, risk: 0.03 };
      case 'big': return { growth: 0.10, risk: 0.025 };
      case 'solid': return { growth: 0.07, risk: 0.02 };
      case 'steady': return { growth: 0.05, risk: 0.02 };
      default: return { growth: 0.07, risk: 0.02 };
    }
  };

  const getDefaultTechnology = (industry) => {
    const techMap = {
      'Information Technology': 'Cloud Computing',
      'Software Development': 'Web Technology',
      'Healthcare': 'Digital Health',
      'Fintech': 'Blockchain',
      'Education': 'EdTech',
      'Clean Energy': 'Renewable Tech',
      'Biotech': 'Biotechnology',
      'Hardware Manufacturing': 'IoT',
      'Industrial Manufacturing': 'Automation',
      'Real Estate': 'PropTech',
      'Transportation': 'Electric Vehicles'
    };
    return techMap[industry] || 'Digital Platform';
  };

  const getDefaultBusinessModel = (industry, hasExistingFunding) => {
    const modelMap = {
      'Information Technology': hasExistingFunding ? 'Enterprise' : 'SaaS',
      'Software Development': 'SaaS',
      'Healthcare': 'B2B2C',
      'Fintech': hasExistingFunding ? 'B2B' : 'B2C',
      'Education': 'B2C',
      'Clean Energy': 'B2B',
      'Biotech': 'B2B',
      'Hardware Manufacturing': 'B2B',
      'Industrial Manufacturing': 'B2B',
      'Real Estate': 'B2C',
      'Transportation': 'B2C'
    };
    return modelMap[industry] || (hasExistingFunding ? 'B2B' : 'B2C');
  };

  const calculateJourney = () => {
    setLoading(true);
    try {
      const { growth, risk } = mapGrowthGoalToRates(formData.growthGoal);
      const yearsProjected = mapTimeHorizonToYears(formData.timeHorizon);
      
      const journeyData = {
        startupId: 'new',
        startupStage: formData.stage,
        industry: formData.industry,
        technology: getDefaultTechnology(formData.industry),
        businessModel: getDefaultBusinessModel(formData.industry, formData.existingFunding),
        employmentDetails: {
          currentSalary: Number(formData.opportunityCost) || 100000,
          projectedGrowth: growth,
          inflationRate: risk,
          yearsProjected: yearsProjected
        },
        ownershipStake: Number(formData.ownershipStake) || 100
      };

      const result = calculateFinancingJourney(journeyData);
      setJourney(result);
      setStep(TOTAL_STEPS + 1);
    } catch (error) {
      console.error('Error calculating journey:', error);
    } finally {
      setLoading(false);
    }
  };

  const isNumberInputStep = (currentStep) => {
    return currentStep === 3 || currentStep === 5 || currentStep === 8;
  };

  const canProceed = () => {
    if (isNumberInputStep(step)) {
      switch (step) {
        case 3: return !!formData.fundingNeeded;
        case 5: return !!formData.opportunityCost;
        case 8: return !!formData.ownershipStake;
        default: return true;
      }
    }
    return true;
  };

  const handleNext = () => {
    if (canProceed()) {
      if (step === TOTAL_STEPS) {
        calculateJourney();
      } else {
        setStep(prev => prev + 1);
      }
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <StageQuestion
            value={formData.stage}
            onChange={handleStageSelect}
          />
        );

      case 2:
        return (
          <SelectQuestion
            title={t('industry.title')}
            options={industries}
            value={formData.industry}
            onChange={handleIndustrySelect}
          />
        );

      case 3:
        return (
          <SelectQuestion
            title={t('fundingNeeded.title')}
            options={getFundingRanges()}
            value={formData.fundingNeeded}
            onChange={(value) => {
              setFormData(prev => ({ ...prev, fundingNeeded: value }));
              setStep(prev => prev + 1);
            }}
          />
        );

      case 4:
        return (
          <SelectQuestion
            title={t('existingFunding.title')}
            options={['yes', 'no']}
            value={formData.existingFunding ? 'yes' : formData.existingFunding === false ? 'no' : null}
            onChange={(value) => handleExistingFundingSelect(value === 'yes')}
            getOptionLabel={(option) => t(`existingFunding.options.${option}`)}
          />
        );

      case 5:
        return (
          <NumberQuestion
            title={t('opportunityCost.title')}
            question={t('opportunityCost.question')}
            value={formData.opportunityCost}
            onChange={(value) => setFormData(prev => ({ ...prev, opportunityCost: value }))}
            onEnterPress={handleNext}
          />
        );

      case 6:
        return (
          <SelectQuestion
            title={t('growthGoal.title')}
            options={growthGoals}
            value={formData.growthGoal}
            onChange={handleGrowthGoalSelect}
            getOptionLabel={(goal) => t(`growthGoal.options.${goal}`)}
          />
        );

      case 7:
        return (
          <SelectQuestion
            title={t('timeHorizon.title')}
            options={timeHorizons}
            value={formData.timeHorizon}
            onChange={handleTimeHorizonSelect}
            getOptionLabel={(horizon) => t(`timeHorizon.options.${horizon}`)}
          />
        );

      case 8:
        return (
          <NumberQuestion
            title={t('ownershipStake.title')}
            question={t('ownershipStake.question')}
            value={formData.ownershipStake}
            onChange={(value) => setFormData(prev => ({ ...prev, ownershipStake: value }))}
            onEnterPress={handleNext}
            min={0}
            max={100}
            step={1}
          />
        );

      case TOTAL_STEPS + 1:
        return journey && <Results journey={journey} formData={formData} />;

      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-2xl">
      <h1 className="text-3xl font-bold mb-8 text-center">{t('title')}</h1>
      {step > 1 && step <= TOTAL_STEPS && (
        <ProgressBar 
          currentStep={step - 1} 
          totalSteps={TOTAL_STEPS - 1} 
          currentStage={formData.stage}
        />
      )}
      
      <div className="bg-gray-800 rounded-lg shadow-lg p-6">
        {renderStep()}
        
        {step > 1 && step <= TOTAL_STEPS && (
          <Navigation
            showBack={true}
            showNext={isNumberInputStep(step)}
            nextDisabled={!canProceed()}
            loading={loading}
            onBack={() => setStep(prev => prev - 1)}
            onNext={handleNext}
          />
        )}
      </div>
    </div>
  );
};

export default FinancingJourneyPage;
