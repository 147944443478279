import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState({
    primaryColor: '#6b5db4',
    secondaryColor: '#4ac080',
    thirdColor: '#4299e1',
    additionalFundingBgColor: '#4c5767',
    cardBgColor: '#1f2937',
    textColor: '#FFFFFF',
    expertBadgeColor: '#4ac080',
    appBackgroundColor: '#111827'
  });

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(JSON.parse(savedTheme));
    }
  }, []);

  const updateTheme = (newTheme) => {
    setTheme(newTheme);
    localStorage.setItem('theme', JSON.stringify(newTheme));
  };

  return (
    <ThemeContext.Provider value={{ theme, updateTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
