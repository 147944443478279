import db from '../config/firestore';
import { collection, getDocs, query, limit, doc } from 'firebase/firestore';

class StartupsSelectionModel {
    constructor() {
        this.collectionName = 'startup-selections';
    }

    async getStartupsSelectionsWithCampaigns(limitCount = 10) {
        try {
            console.log(`Fetching startup selections with campaigns (limit: ${limitCount})...`);
            const startupsSelectionsRef = collection(db, this.collectionName);
            const selectionsQuery = query(startupsSelectionsRef, limit(limitCount));
            const selectionsSnapshot = await getDocs(selectionsQuery);

            const selectionsWithCampaigns = await Promise.all(selectionsSnapshot.docs.map(async (selectionDoc) => {
                const selection = {
                    id: selectionDoc.id,
                    ...selectionDoc.data(),
                    startupCampaigns: []
                };

                const campaignsRef = collection(doc(db, this.collectionName, selectionDoc.id), 'startup-campaigns');
                const campaignsSnapshot = await getDocs(campaignsRef);

                selection.startupCampaigns = campaignsSnapshot.docs.map(campaignDoc => ({
                    id: campaignDoc.id,
                    ...campaignDoc.data()
                }));

                return selection;
            }));

            console.log(`Fetched ${selectionsWithCampaigns.length} startup selections with their campaigns`);
            console.log('Fetched selections with campaigns:', selectionsWithCampaigns);

            return selectionsWithCampaigns;
        } catch (error) {
            console.error('Error fetching startups selections with campaigns:', error);
            throw error;
        }
    }

    // Add more methods as needed, e.g., getStartupsSelectionById, createStartupsSelection, etc.
}

export const startupsSelectionModel = new StartupsSelectionModel();