import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../ThemeContext';
import { Button } from '../components/ui/Button';
import { Card } from '../components/ui/Card';
import { auth } from '../config/firebase';
import db from '../config/firestore';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

const AddLeadInvestorPage = () => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    photo: '',
    bio: '',
    credentials: [''],
    areasOfExpertise: [''],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [canCreateLeadInvestor, setCanCreateLeadInvestor] = useState(false);
  const [userClaims, setUserClaims] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log('User is signed in:', user.uid);
        checkUserPermission(user);
      } else {
        console.log('No user is signed in');
        setCanCreateLeadInvestor(false);
        setUserClaims(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const checkUserPermission = async (user) => {
    try {
      const idTokenResult = await user.getIdTokenResult(true);
      console.log('User claims:', idTokenResult.claims);
      setUserClaims(idTokenResult.claims);
      
      const roles = idTokenResult.claims.roles || {};
      const hasPermission = roles.startup_investor || roles.startup_selector || roles.steerup_platform_admin;
      
      console.log('Has permission:', hasPermission);
      setCanCreateLeadInvestor(hasPermission);
    } catch (error) {
      console.error('Error checking user permission:', error);
      setCanCreateLeadInvestor(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleArrayChange = (index, field, value) => {
    setFormData(prevState => {
      const newArray = [...prevState[field]];
      newArray[index] = value;
      return { ...prevState, [field]: newArray };
    });
  };

  const addArrayItem = (field) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: [...prevState[field], '']
    }));
  };

  const removeArrayItem = (field, index) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: prevState[field].filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!canCreateLeadInvestor) {
      setSubmitMessage('You do not have permission to add lead investors.');
      return;
    }

    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const user = auth.currentUser;

      if (!user) {
        throw new Error('User not authenticated');
      }

      const leadInvestorsRef = collection(db, 'lead_investors');

      const docRef = await addDoc(leadInvestorsRef, {
        ...formData,
        credentials: formData.credentials.filter(cred => cred.trim() !== ''),
        areasOfExpertise: formData.areasOfExpertise.filter(area => area.trim() !== ''),
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });

      console.log('Lead investor added successfully:', docRef.id);
      setSubmitMessage('Lead investor added successfully');
      setFormData({
        name: '',
        photo: '',
        bio: '',
        credentials: [''],
        areasOfExpertise: [''],
      });
    } catch (error) {
      console.error('Error adding lead investor:', error);
      setSubmitMessage(`Error: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const inputStyle = `w-full p-2 border rounded-md ${theme.inputBgColor} ${theme.inputBorderColor} text-black`;

  return (
    <div className={`min-h-screen py-4 px-6 ${theme.bgColor}`}>
      <Card className="max-w-2xl mx-auto p-6">
        <h1 className={`text-2xl font-bold mb-6 ${theme.textColor}`}>Add Lead Investor</h1>
        {!canCreateLeadInvestor ? (
          <div>
            <p className={`${theme.textColor} mb-4`}>You do not have permission to add lead investors.</p>
            <p className={`${theme.textColor} mb-4`}>User claims: {JSON.stringify(userClaims)}</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="name" className={`block mb-1 font-medium ${theme.labelColor}`}>{t('addLeadInvestorPage.name')}</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={inputStyle}
                required
              />
            </div>
            <div>
              <label htmlFor="photo" className={`block mb-1 font-medium ${theme.labelColor}`}>Photo (Emoji or image URL)</label>
              <input
                type="text"
                id="photo"
                name="photo"
                value={formData.photo}
                onChange={handleChange}
                className={inputStyle}
                placeholder="Enter emoji or image URL"
              />
            </div>
            <div>
              <label htmlFor="bio" className={`block mb-1 font-medium ${theme.labelColor}`}>Bio</label>
              <textarea
                id="bio"
                name="bio"
                value={formData.bio}
                onChange={handleChange}
                className={`${inputStyle} h-24`}
              />
            </div>
            <div>
              <label className={`block mb-1 font-medium ${theme.labelColor}`}>Credentials</label>
              {formData.credentials.map((credential, index) => (
                <div key={index} className="flex mb-2">
                  <input
                    type="text"
                    value={credential}
                    onChange={(e) => handleArrayChange(index, 'credentials', e.target.value)}
                    className={`${inputStyle} flex-grow`}
                  />
                  <button
                    type="button"
                    onClick={() => removeArrayItem('credentials', index)}
                    className="ml-2 px-2 py-1 bg-red-500 text-white rounded"
                  >
                    -
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => addArrayItem('credentials')}
                className="mt-2 px-2 py-1 bg-green-500 text-white rounded"
              >
                +
              </button>
            </div>
            <div>
              <label className={`block mb-1 font-medium ${theme.labelColor}`}>Areas of Expertise</label>
              {formData.areasOfExpertise.map((area, index) => (
                <div key={index} className="flex mb-2">
                  <input
                    type="text"
                    value={area}
                    onChange={(e) => handleArrayChange(index, 'areasOfExpertise', e.target.value)}
                    className={`${inputStyle} flex-grow`}
                  />
                  <button
                    type="button"
                    onClick={() => removeArrayItem('areasOfExpertise', index)}
                    className="ml-2 px-2 py-1 bg-red-500 text-white rounded"
                  >
                    -
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => addArrayItem('areasOfExpertise')}
                className="mt-2 px-2 py-1 bg-green-500 text-white rounded"
              >
                +
              </button>
            </div>
            <Button
              type="submit"
              className="w-full mt-4"
              style={{
                backgroundColor: theme.primaryColor,
                color: 'white',
                padding: '0.5rem 1rem',
                fontSize: '1rem',
                fontWeight: 'bold',
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? t('common.submitting') : t('addLeadInvestorPage.submit')}
            </Button>
          </form>
        )}
        {submitMessage && (
          <p className={`mt-4 text-center ${submitMessage.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>
            {submitMessage}
          </p>
        )}
      </Card>
    </div>
  );
};

export default AddLeadInvestorPage;