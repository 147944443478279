import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../ThemeContext';
import AmplifierVisual from './AmplifierVisual';

const ValueProposition = () => {
  const { t } = useTranslation('businessDev');
  const { theme } = useContext(ThemeContext);

  return (
    <div className="rounded-xl p-4 md:p-8 lg:p-12 xl:p-16">
      <h2 
        className="text-2xl md:text-3xl font-bold mb-12 text-center md:text-left"
        style={{ color: theme.textColor }}
      >
        {t('valueProposition.title')}
      </h2>
      <div className="flex flex-col md:flex-row items-start space-y-8 md:space-y-0 md:space-x-8 lg:space-x-12">
        {/* Amplifier Visual */}
        <div className="w-full md:w-3/5">
          <AmplifierVisual />
        </div>

        {/* Output and Benefits Container */}
        <div className="w-full md:w-2/5">
          <div 
            className="rounded-2xl p-6 md:p-8 lg:p-10"
            style={{ 
              backgroundColor: theme.primaryColor + '08',
              border: `1px solid ${theme.primaryColor}15`
            }}
          >
            {/* 2X More Deals */}
            <div className="mb-6 md:mb-8">
              <div 
                className="text-5xl md:text-7xl lg:text-8xl font-bold" 
                style={{ color: theme.primaryColor }}
              >
                {t('valueProposition.multiplier')}
              </div>
              <div 
                className="text-xl md:text-3xl lg:text-4xl font-bold whitespace-nowrap" 
                style={{ color: theme.primaryColor }}
              >
                {t('valueProposition.moreDeals')}
              </div>
            </div>

            {/* Benefits List */}
            <div className="space-y-4 md:space-y-6">
              {[2, 3, 4, 5].map((index) => (
                <div key={index} className="flex items-start space-x-4">
                  <div 
                    className="w-2.5 h-2.5 rounded-full flex-shrink-0 mt-2"
                    style={{ backgroundColor: theme.primaryColor }}
                  />
                  <p 
                    className="text-base md:text-lg leading-relaxed"
                    style={{ color: theme.textColor }}
                  >
                    {t(`valueProposition.benefits.${index}`)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValueProposition;
