/**
 * Calculate employment earnings over time
 * @param {number} currentSalary Initial salary
 * @param {number} growthRate Annual growth rate
 * @param {number} inflationRate Annual inflation rate
 * @param {number} years Number of years
 * @returns {number} Total earnings adjusted for growth and inflation
 */
function calculateEmploymentEarnings(currentSalary, growthRate, inflationRate, years) {
  let totalEarnings = 0;
  let yearSalary = currentSalary;

  for (let year = 0; year < years; year++) {
    // Apply growth and inflation
    yearSalary *= (1 + growthRate) * (1 + inflationRate);
    totalEarnings += yearSalary;
  }

  return totalEarnings;
}

/**
 * Calculate financing stages needed to reach exit value
 * @param {number} exitValue Target exit value
 * @param {number} years Timeline in years
 * @param {string} startupStage Current stage of the startup
 * @returns {Array} Array of financing stages
 */
function calculateFinancingPath(exitValue, years, startupStage) {
  const stages = [];
  const monthsBetweenRounds = 18; // Typical time between rounds
  
  // Adjust initial valuation based on startup stage
  let currentValuation;
  let startMonth;
  
  switch (startupStage) {
    case 'ideation':
      currentValuation = exitValue / 12; // Lower initial valuation for ideation stage
      startMonth = 0;
      break;
    case 'marketTested':
      currentValuation = exitValue / 8; // Standard initial valuation
      startMonth = 6;
      break;
    case 'upAndRunning':
      currentValuation = exitValue / 6; // Higher initial valuation for running startups
      startMonth = 12;
      break;
    case 'growthReady':
      currentValuation = exitValue / 4; // Highest initial valuation for growth stage
      startMonth = 18;
      break;
    default:
      currentValuation = exitValue / 8;
      startMonth = 0;
  }

  let currentMonth = startMonth;

  while (currentValuation >= 1000000 && stages.length < 5) { // Minimum $1M valuation
    const stage = {
      stage: stages.length === 0 ? 'seed' : `series${String.fromCharCode(65 + stages.length - 1)}`,
      timing: currentMonth,
      minimumValuation: currentValuation,
      minimumRaise: currentValuation * 0.2, // Typical raise is 20% of valuation
      expectedDilution: 0.20 // 20% dilution per round
    };

    stages.push(stage);
    currentValuation = currentValuation * 0.6; // Work backwards, each previous round ~60% of next
    currentMonth += monthsBetweenRounds;
  }

  // Add exit stage
  stages.push({
    stage: 'exit',
    timing: years * 12,
    minimumValuation: exitValue,
    minimumRaise: 0,
    expectedDilution: 0
  });

  return stages.reverse(); // Return in chronological order
}

/**
 * Generate risk analysis based on financing path and startup details
 * @param {Array} financingPath Financing stages
 * @param {string} startupStage Current stage of the startup
 * @param {string} industry Industry of the startup
 * @returns {Object} Risk analysis object
 */
function generateRiskAnalysis(financingPath, startupStage, industry) {
  const baseRisks = [
    {
      category: 'market',
      description: 'Market size may not support target valuation',
      mitigationStrategy: 'Validate TAM/SAM/SOM assumptions with market research'
    },
    {
      category: 'execution',
      description: 'Missing key milestones could impact future rounds',
      mitigationStrategy: 'Define clear KPIs and maintain regular investor updates'
    },
    {
      category: 'financial',
      description: 'Runway may be insufficient between rounds',
      mitigationStrategy: 'Maintain 18-24 months runway and start fundraising early'
    },
    {
      category: 'team',
      description: 'Key talent acquisition and retention',
      mitigationStrategy: 'Implement competitive compensation and equity packages'
    }
  ];

  // Add stage-specific risks
  const stageRisks = {
    ideation: {
      category: 'market',
      description: 'Product-market fit not yet validated',
      mitigationStrategy: 'Conduct extensive customer interviews and market validation'
    },
    marketTested: {
      category: 'execution',
      description: 'Moving from validation to product development',
      mitigationStrategy: 'Build MVP with core features based on market feedback'
    },
    upAndRunning: {
      category: 'financial',
      description: 'Managing cash burn during growth phase',
      mitigationStrategy: 'Focus on unit economics and sustainable growth metrics'
    },
    growthReady: {
      category: 'execution',
      description: 'Scaling operations while maintaining quality',
      mitigationStrategy: 'Implement scalable processes and strengthen management team'
    }
  };

  const keyRisks = [...baseRisks];
  if (stageRisks[startupStage]) {
    keyRisks.push(stageRisks[startupStage]);
  }

  // Calculate recommended runway based on financing path
  let avgTimeBetweenRounds = 18; // Default to 18 months if we can't calculate
  if (financingPath.length > 1) {
    const timeDiffs = financingPath
      .map((stage, i) => i > 0 ? stage.timing - financingPath[i-1].timing : 0)
      .filter(time => time > 0);
    
    if (timeDiffs.length > 0) {
      avgTimeBetweenRounds = timeDiffs.reduce((sum, time) => sum + time, 0) / timeDiffs.length;
    }
  }

  return {
    keyRisks,
    runwayMonths: Math.max(18, Math.round(avgTimeBetweenRounds * 0.8)) // 80% of time between rounds or minimum 18 months
  };
}

/**
 * Calculate the complete financing journey
 * @param {Object} data Journey calculation input data
 * @returns {Object} Calculated financing journey
 */
export function calculateFinancingJourney(data) {
  const { startupId, employmentDetails, startupStage, industry, technology, businessModel } = data;

  // Calculate employment earnings
  const cumulativeLostSalary = calculateEmploymentEarnings(
    employmentDetails.currentSalary,
    employmentDetails.projectedGrowth,
    employmentDetails.inflationRate,
    employmentDetails.yearsProjected
  );

  // Apply risk premium
  const riskPremium = 2;
  const minimumExitValue = cumulativeLostSalary * riskPremium;

  // Calculate financing path
  const financingPath = calculateFinancingPath(minimumExitValue, employmentDetails.yearsProjected, startupStage);

  // Generate risk analysis
  const riskAnalysis = generateRiskAnalysis(financingPath, startupStage, industry);

  // Calculate catch-up year (when exit value exceeds cumulative employment earnings)
  const catchupYear = Math.ceil(
    financingPath.findIndex(stage => stage.minimumValuation > cumulativeLostSalary) / 12
  );

  // Create journey object
  return {
    startupId,
    startupStage,
    industry,
    technology,
    businessModel,
    employmentDetails,
    exitCalculation: {
      minimumExitValue,
      cumulativeLostSalary,
      riskPremium,
      catchupYear
    },
    financingPath,
    riskAnalysis
  };
}
