import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../ThemeContext';

const StartupsSelectionCard = ({
  id,
  name,
  experts,
  startups,
  fundingGoal,
  currentFunding,
  backers,
  remainingDays,
  additionalFunding,
  leadInvestor
}) => {
  const { theme } = useContext(ThemeContext);
  const { t, i18n } = useTranslation(['components', 'common']);
  const navigate = useNavigate();

  const formatAmount = (amount) => {
    return new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const fundingPercentage = fundingGoal > 0 ? (currentFunding / fundingGoal) * 100 : 0;

  const handleStartupClick = (startupId) => {
    navigate(`/startup/${startupId}`);
  };

  return (
    <div className="rounded-lg overflow-hidden" style={{ backgroundColor: theme.cardBgColor, color: theme.textColor }}>
      <div className="p-4">
        {/* Header */}
        <div className="flex items-center space-x-2 mb-4">
          <div className="w-7 h-7 bg-purple-600 rounded-full flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
              <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
            </svg>
          </div>
          <h2 className="text-lg font-bold">{name || t('components:startupsSelectionCard.unnamedSelection')}</h2>
        </div>

        {/* Lead Investor */}
        {leadInvestor && (
          <div className="mb-4">
            <p className="text-green-500 text-xs mb-2">{t('components:startupsSelectionCard.leadExpertInvestment')}</p>
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center space-x-2">
                {leadInvestor.avatar && (
                  <img 
                    src={leadInvestor.avatar}
                    alt={t('components:startupsSelectionCard.expertAvatar', { name: leadInvestor.name })} 
                    className="w-12 h-12 rounded-full object-cover"
                  />
                )}
                <div>
                  <p className="text-sm font-medium">{leadInvestor.name}</p>
                  <p className="text-xs text-gray-400">{leadInvestor.credentials}</p>
                </div>
              </div>
              <div className="text-sm font-medium text-right">
                <p className="text-gray-400">{t('components:startupsSelectionCard.leadExpertInvestment')}:</p>
                <p>+{formatAmount(leadInvestor.investment || 0)}</p>
              </div>
            </div>
          </div>
        )}

        {/* Selection Leads */}
        <div className="mb-4">
          <p className="text-green-500 text-xs mb-2">{t('components:startupsSelectionCard.selectionLead')}</p>
          {experts && experts.map((expert, index) => (
            <div key={index} className="flex justify-between items-center mb-2">
              <div className="flex items-center space-x-2">
                {expert.photo && (
                  <img 
                    src={expert.photo}
                    alt={t('components:startupsSelectionCard.expertAvatar', { name: expert.name })} 
                    className="w-10 h-10 rounded-full object-cover"
                  />
                )}
                <div>
                  <p className="text-sm font-medium">{expert.name}</p>
                  <p className="text-xs text-gray-400">{expert.credentials}</p>
                </div>
              </div>
              <div className="text-sm font-medium text-right">
                <p className="text-gray-400">{t('components:startupsSelectionCard.leadExpertInvestment')}:</p>
                <p>+{formatAmount(expert.investment || 0)}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Startups */}
        <p className="text-green-500 text-xs mb-1">STARTUPS</p>
        <div className="grid grid-cols-1 gap-2 mb-3">
          {startups && startups.map((startup, index) => (
            <div 
              key={index} 
              className="relative rounded-lg overflow-hidden cursor-pointer" 
              style={{ height: '80px' }}
              onClick={() => handleStartupClick(startup.id)}
            >
              {startup.logo && (
                <img 
                  src={startup.logo}
                  alt={startup.name || t('components:startupsSelectionCard.unnamedStartup')} 
                  className="w-full h-full object-cover"
                />
              )}
              <div className="absolute inset-0 bg-black bg-opacity-50"></div>
              <div className="absolute inset-0 p-2 flex flex-col justify-end">
                <p className="text-xs font-medium text-white">
                  {startup.name || t('components:startupsSelectionCard.unnamedStartup')}
                </p>
                <p className="text-xs text-gray-300 truncate">
                  {startup.description || t('components:startupsSelectionCard.noDescription')}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Funding Goal */}
        <div className="mb-4">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-sm font-semibold">
              {t('components:startupsSelectionCard.goal', {
                current: formatAmount(currentFunding || 0),
                total: formatAmount(fundingGoal || 0)
              })}
            </h3>
            <span className="text-green-500 text-sm">{formatAmount(currentFunding || 0)}</span>
          </div>
          <div className="relative pt-1">
            <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-700">
              <div
                style={{ width: `${fundingPercentage}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
              ></div>
            </div>
          </div>
          <div className="flex justify-between items-center text-xs mt-2">
            <span>{fundingPercentage.toFixed(0)}%</span>
            <div>
              <span>{t('components:startupsSelectionCard.daysLeftBackers', { days: remainingDays || 0, backers: backers || 0 })}</span>
            </div>
          </div>
        </div>

        {/* Contribute Button */}
        <button
          className="w-full py-2 rounded-full text-sm font-bold mb-4 bg-purple-600 text-white"
          onClick={() => navigate(`/contribute/${id}`)}
        >
          {t('components:startupsSelectionCard.contribute')}
        </button>

        {/* Additional Funding */}
        <p className="text-green-500 text-xs mb-2">{t('components:startupsSelectionCard.additionalFunding')}</p>
        <div className="space-y-2">
          {(additionalFunding || []).map((funding, index) => (
            <div
              key={index}
              className="bg-gray-800 rounded-lg p-2 flex justify-between items-center"
            >
              <div>
                <p className="text-xs font-medium">
                  {funding.source?.name || t('components:startupsSelectionCard.unknownSource')}
                </p>
                <p className="text-xs text-gray-400">
                  {funding.type || t('components:startupsSelectionCard.unknownType')}
                </p>
              </div>
              <div className="bg-gray-700 px-2 py-1 rounded-full text-xs">
                {formatAmount(funding.amount || 0)}
              </div>
            </div>
          ))}
          {(!additionalFunding || additionalFunding.length === 0) && (
            <p className="text-sm text-gray-400">{t('components:startupsSelectionCard.noAdditionalFunding')}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default StartupsSelectionCard;
