import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StartupsSelectionCard from '../components/StartupsSelectionCard';
import { startupsSelectionModel } from '../models/startupsSelection';

const ExploreSelections = () => {
    const [selections, setSelections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { t } = useTranslation(['pages', 'common']);

    useEffect(() => {
        const fetchSelectionsAndCampaigns = async () => {
            try {
                console.log('Fetching startup selections with campaigns...');
                const fetchedSelectionsWithCampaigns = await startupsSelectionModel.getStartupsSelectionsWithCampaigns();
                console.log('Fetched selections with campaigns:', fetchedSelectionsWithCampaigns);
                setSelections(fetchedSelectionsWithCampaigns);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching startup selections and campaigns:', err);
                setError(t('pages:explore.errorFetching', { error: err.message }));
                setLoading(false);
            }
        };

        fetchSelectionsAndCampaigns();
    }, [t]);

    if (loading) {
        return <div className="text-center py-8">{t('common:loading')}</div>;
    }

    if (error) {
        return <div className="text-center py-8 text-red-500">{error}</div>;
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">{t('pages:explore.title')}</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {selections.length === 0 ? (
                    <p>{t('pages:explore.noSelections', { count: selections.length })}</p>
                ) : (
                    selections.map(selection => (
                        <StartupsSelectionCard 
                            key={selection.id} 
                            {...selection}
                        />
                    ))
                )}
            </div>
        </div>
    );
};

export default ExploreSelections;
