import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Briefcase, TrendingUp, Bell, User, PieChart, DollarSign, Users, Search } from 'lucide-react';
import { ThemeContext } from '../ThemeContext';
import { useTranslation } from 'react-i18next';

const Card = ({ title, children }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="bg-gray-800 p-4 rounded-lg mb-4" style={{ backgroundColor: theme.cardBgColor }}>
      <h3 className="text-lg font-bold mb-2" style={{ color: theme.textColor }}>{title}</h3>
      {children}
    </div>
  );
};

const StatItem = ({ icon: Icon, label, value }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="flex items-center mb-2">
      <Icon className="w-5 h-5 mr-2" style={{ color: theme.secondaryColor }} />
      <span className="text-sm" style={{ color: theme.textColor }}>{label}:</span>
      <span className="ml-auto font-bold" style={{ color: theme.textColor }}>{value}</span>
    </div>
  );
};

const SelectionItem = ({ name, progress }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="mb-2">
      <div className="flex justify-between items-center mb-1">
        <span className="text-sm" style={{ color: theme.textColor }}>{name}</span>
        <span className="text-xs" style={{ color: theme.secondaryColor }}>{progress}%</span>
      </div>
      <div className="w-full bg-gray-700 rounded-full h-2">
        <div
          className="bg-secondary rounded-full h-2"
          style={{ width: `${progress}%`, backgroundColor: theme.secondaryColor }}
        ></div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation(['pages', 'common']);

  return (
    <div className="min-h-screen p-4">
      <header className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold" style={{ color: theme.textColor }}>{t('common:dashboard')}</h1>
        <div className="flex space-x-2">
          <Bell className="w-6 h-6" style={{ color: theme.textColor }} />
          <Link to="/profile">
            <User className="w-6 h-6" style={{ color: theme.textColor }} />
          </Link>
        </div>
      </header>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Card title={t('pages:dashboard.portfolioOverview')}>
          <StatItem icon={Briefcase} label={t('pages:dashboard.totalInvested')} value="$50,000" />
          <StatItem icon={PieChart} label={t('pages:dashboard.activeSelections')} value="3" />
          <StatItem icon={TrendingUp} label={t('pages:dashboard.overallGrowth')} value="+15.3%" />
          <Link to="/investments" className="text-sm font-bold" style={{ color: theme.secondaryColor }}>
            {t('pages:dashboard.viewDetails')}
          </Link>
        </Card>

        <Card title={t('pages:dashboard.investmentSummary')}>
          <StatItem icon={DollarSign} label={t('pages:dashboard.availableFunds')} value="$10,000" />
          <StatItem icon={Users} label={t('pages:dashboard.backedStartups')} value="12" />
          <Link to="/explore" className="text-sm font-bold" style={{ color: theme.secondaryColor }}>
            {t('pages:dashboard.exploreMore')}
          </Link>
        </Card>
      </div>

      <Card title={t('pages:dashboard.activeSelections')}>
        <SelectionItem name={t('pages:dashboard.selections.immersiveGaming')} progress={75} />
        <SelectionItem name={t('pages:dashboard.selections.aiHealthTech')} progress={45} />
        <SelectionItem name={t('pages:dashboard.selections.sustainableEnergy')} progress={60} />
        <Link to="/investments" className="text-sm font-bold" style={{ color: theme.secondaryColor }}>
          {t('pages:dashboard.viewAllInvestments')}
        </Link>
      </Card>

      <Card title={t('pages:dashboard.recommendedSelections')}>
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <span style={{ color: theme.textColor }}>{t('pages:dashboard.selections.cleanTech')}</span>
            <Link to="/selection/cleantech" className="px-2 py-1 text-xs rounded" style={{ backgroundColor: theme.primaryColor, color: theme.textColor }}>
              {t('common:view')}
            </Link>
          </div>
          <div className="flex justify-between items-center">
            <span style={{ color: theme.textColor }}>{t('pages:dashboard.selections.finTech')}</span>
            <Link to="/selection/fintech" className="px-2 py-1 text-xs rounded" style={{ backgroundColor: theme.primaryColor, color: theme.textColor }}>
              {t('common:view')}
            </Link>
          </div>
        </div>
        <Link to="/explore" className="text-sm font-bold mt-2 inline-block" style={{ color: theme.secondaryColor }}>
          <Search className="w-4 h-4 inline mr-1" />
          {t('pages:dashboard.exploreAllSelections')}
        </Link>
      </Card>
    </div>
  );
};

export default Dashboard;
