import React from 'react';
import PropTypes from 'prop-types';

const IPhoneMockup = ({ screenshotSrc, width = 200, className = '' }) => {
  // Calculate height based on 2:1 ratio
  const height = width * 2;

  return (
    <div
      className={`relative rounded-[30px] overflow-hidden bg-black ${className}`}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        border: '2px solid #000',
        boxShadow: '0 0 0 1px #333'
      }}
    >
      {/* Screenshot */}
      <img
        src={screenshotSrc}
        alt="App screenshot"
        className="w-full h-full object-cover rounded-[28px]"
        loading="lazy"
      />

      {/* Dynamic Island */}
      <div
        className="absolute top-2 left-1/2 transform -translate-x-1/2 bg-black rounded-[12px]"
        style={{
          width: `${width * 0.3}px`,
          height: `${width * 0.06}px`
        }}
      />
    </div>
  );
};

IPhoneMockup.propTypes = {
  screenshotSrc: PropTypes.string.isRequired,
  width: PropTypes.number,
  className: PropTypes.string
};

export default IPhoneMockup;
