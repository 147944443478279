import React, { useContext, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../ThemeContext';

const CodeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5" />
  </svg>
);

const CommunityIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
  </svg>
);

const CalendarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
  </svg>
);

const ComplianceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z" />
  </svg>
);

const AchievementCard = ({ icon: Icon, title, status, description, children }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      className="rounded-xl p-3 md:p-6 border border-opacity-25 w-full"
      style={{ borderColor: `${theme.primaryColor}80` }}
    >
      <div className="flex items-center gap-4 mb-4">
        <div className="w-12 h-12 rounded-lg bg-opacity-20 flex items-center justify-center text-white"
          style={{ backgroundColor: theme.primaryColor }}>
          <Icon />
        </div>
        <div>
          <h3 className="text-xl font-bold text-white">{title}</h3>
          <p className="text-base mt-1" style={{ color: theme.primaryColor }}>{status}</p>
        </div>
      </div>
      <p className="text-gray-400 text-base leading-relaxed mt-2 mb-6">{description}</p>
      <div className="space-y-4">
        {children}
      </div>
    </div>
  );
};

const RadioList = ({ items }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className="space-y-3">
      {items.map((item, index) => (
        <div key={index} className="flex items-start gap-3">
          <div
            className="w-2 h-2 rounded-full mt-2 flex-shrink-0"
            style={{ backgroundColor: theme.primaryColor }}
          />
          <p className="text-gray-400 text-base leading-relaxed">{item}</p>
        </div>
      ))}
    </div>
  );
};

const EventCard = ({ event }) => {
  const { t } = useTranslation('businessDev');
  const { theme } = useContext(ThemeContext);

  return (
    <div className="bg-[#282C34] rounded-lg p-3 md:p-4">
      <div className="flex items-center gap-3 text-white mb-2">
        <div className="text-gray-400">
          <CalendarIcon />
        </div>
        <h4 className="font-medium text-base">{t('pilotLaunchRoadmap.steerupCommunity.upcomingEvents.title')}</h4>
      </div>
      <div className="flex items-start gap-3">
        <div
          className="w-2 h-2 rounded-full mt-2 flex-shrink-0"
          style={{ backgroundColor: theme.primaryColor }}
        />
        <p className="text-white text-base leading-relaxed">{event}</p>
      </div>
    </div>
  );
};

const PilotLaunchRoadmap = forwardRef((props, ref) => {
  const { t } = useTranslation('businessDev');
  const { theme } = useContext(ThemeContext);

  return (
    <div className="md:p-8 lg:p-12 xl:p-16 w-full">
      <h2
        ref={ref}
        className="text-xl md:text-2xl font-bold mb-6 text-center"
        style={{ color: theme.primaryColor }}
      >
        {t('pilotLaunchRoadmap.title')}
      </h2>

      <div className="grid gap-6 w-full">
        <AchievementCard
          icon={CommunityIcon}
          title={t('pilotLaunchRoadmap.steerupCommunity.title')}
          status={t('pilotLaunchRoadmap.steerupCommunity.status')}
          description={t('pilotLaunchRoadmap.steerupCommunity.description')}
        >
          <RadioList
            items={[
              t('pilotLaunchRoadmap.steerupCommunity.points.1'),
              t('pilotLaunchRoadmap.steerupCommunity.points.2')
            ]}
          />
          <EventCard event={t('pilotLaunchRoadmap.steerupCommunity.upcomingEvents.meetGreet')} />
        </AchievementCard>

        <AchievementCard
          icon={CodeIcon}
          title={t('pilotLaunchRoadmap.webApp.title')}
          status={t('pilotLaunchRoadmap.webApp.status')}
          description={t('pilotLaunchRoadmap.webApp.description')}
        >
          <RadioList
            items={[
              t('pilotLaunchRoadmap.webApp.features.1'),
              t('pilotLaunchRoadmap.webApp.features.2'),
              t('pilotLaunchRoadmap.webApp.features.3')
            ]}
          />
        </AchievementCard>

        <AchievementCard
          icon={ComplianceIcon}
          title={t('pilotLaunchRoadmap.compliance.title')}
          status={t('pilotLaunchRoadmap.compliance.status')}
          description={t('pilotLaunchRoadmap.compliance.description')}
        >
          <RadioList
            items={[
              t('pilotLaunchRoadmap.compliance.features.1'),
              t('pilotLaunchRoadmap.compliance.features.2'),
              t('pilotLaunchRoadmap.compliance.features.3')
            ]}
          />
        </AchievementCard>
      </div>
    </div>
  );
});

PilotLaunchRoadmap.displayName = 'PilotLaunchRoadmap';

export default PilotLaunchRoadmap;
