import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../ThemeContext';

const Navigation = ({ onBack, onNext, showBack = true, showNext = false, nextDisabled = false, loading = false }) => {
  const { t } = useTranslation(['common', 'financingJourney']);
  const { theme } = useContext(ThemeContext);

  return (
    <div className="mt-6 flex justify-between">
      {showBack && (
        <button
          onClick={onBack}
          className="px-4 py-2 text-sm font-medium text-white bg-gray-700 rounded-lg hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
          style={{ '--tw-ring-color': theme.primaryColor }}
        >
          {t('common:back')}
        </button>
      )}
      {showNext && (
        <button
          onClick={onNext}
          disabled={nextDisabled || loading}
          className={`px-4 py-2 text-sm font-medium text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed ${!showBack ? 'ml-auto' : ''}`}
          style={{ 
            backgroundColor: theme.primaryColor,
            '--tw-ring-color': theme.primaryColor
          }}
        >
          {loading ? t('calculating') : t('common:next')}
        </button>
      )}
    </div>
  );
};

export default Navigation;
