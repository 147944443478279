import React from 'react';
import { FaLinkedin } from 'react-icons/fa';

const InvestmentTeamMemberCard = ({ name, position, investmentYears, dealsCount, theme, profilePic, linkedinUrl }) => {
  return (
    <a
      href={linkedinUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-start gap-3 rounded-xl py-1.5 px-3 mb-2 last:mb-0 hover:bg-[#30363D] transition-colors duration-200"
      style={{
        border: `1px solid ${theme.primaryColor}40`
      }}
    >
      <img
        src={`/images/business-dev/${profilePic}`}
        alt={name}
        className="w-12 h-12 rounded-full object-cover flex-shrink-0"
      />
      <div className="min-w-0 flex-grow">
        <div className="flex items-center justify-between mb-0.5">
          <div className="flex items-center gap-2 min-w-0">
            <h3 className="text-base font-bold text-white whitespace-nowrap">{name}</h3>
            <span className="text-gray-400 text-sm truncate">{position}</span>
          </div>
          <FaLinkedin className="text-gray-400 hover:text-blue-500 flex-shrink-0 ml-2" />
        </div>
        <div className="flex flex-col text-gray-400 text-sm">
          <span>{investmentYears}</span>
          <span>{dealsCount}</span>
        </div>
      </div>
    </a>
  );
};

export default InvestmentTeamMemberCard;
