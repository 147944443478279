import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../../ThemeContext';

const stages = ['ideation', 'marketTested', 'upAndRunning', 'growthReady'];

const StageQuestion = ({ value, onChange }) => {
  const { t } = useTranslation(['financingJourney']);
  const { theme } = useContext(ThemeContext);

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold mb-4">{t('stages.title')}</h2>
      <div className="grid gap-4">
        {stages.map((stage) => (
          <button
            key={stage}
            onClick={() => onChange(stage)}
            className={`p-4 rounded-lg border transition-colors duration-200 ${
              value === stage
                ? 'border-primary bg-opacity-10'
                : 'border-gray-700 hover:border-gray-600'
            }`}
            style={{
              borderColor: value === stage ? theme.primaryColor : undefined,
              backgroundColor: value === stage ? `${theme.primaryColor}1a` : undefined
            }}
          >
            <div className="flex items-start">
              <span className="text-2xl mr-4 mt-1">
                {t(`stages.${stage}.icon`)}
              </span>
              <div className="flex-1 text-left">
                <h3 className="font-medium text-lg mb-1">
                  {t(`stages.${stage}.title`)}
                </h3>
                <p className="text-sm text-gray-400">
                  {t(`stages.${stage}.description`)}
                </p>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default StageQuestion;
