import UserModel from './user';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup, deleteUser } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import functions from '../config/functions';

class UserAuthModel extends UserModel {
  constructor() {
    super();
    this.auth = getAuth();
  }

  async signIn(email, password) {
    try {
      const userCredential = await signInWithEmailAndPassword(this.auth, email, password);
      this.setUid(userCredential.user.uid);
      return await this.get();
    } catch (error) {
      throw new Error(`Sign in failed: ${error.message}`);
    }
  }

  async signUp(email, password, displayName) {
    let userCredential;
    try {
      userCredential = await createUserWithEmailAndPassword(this.auth, email, password);
      this.setUid(userCredential.user.uid);
      this.data.email = email;
      this.data.displayName = displayName;

      try {
        await this.create();
        await this.setCustomClaimsForUser({ startup_investor: true });
      } catch (firestoreError) {
        // If Firestore creation fails, delete the user from Firebase Auth
        await deleteUser(userCredential.user);
        throw new Error(`Failed to create user in Firestore: ${firestoreError.message}`);
      }

      return this.data;
    } catch (error) {
      throw new Error(`Sign up failed: ${error.message}`);
    }
  }

  async signOut() {
    try {
      await signOut(this.auth);
      this.data = {};
      this.userRef = null;
    } catch (error) {
      throw new Error(`Sign out failed: ${error.message}`);
    }
  }

  async resetPassword(email) {
    try {
      await sendPasswordResetEmail(this.auth, email);
    } catch (error) {
      throw new Error(`Password reset failed: ${error.message}`);
    }
  }

  async signInWithGoogle() {
    let userCredential;
    try {
      const provider = new GoogleAuthProvider();
      userCredential = await signInWithPopup(this.auth, provider);
      this.setUid(userCredential.user.uid);
      this.data.email = userCredential.user.email;
      this.data.displayName = userCredential.user.displayName;

      let isNewUser = false;
      try {
        await this.get();
      } catch (error) {
        if (error.message === 'User not found') {
          isNewUser = true;
        } else {
          throw error;
        }
      }

      if (isNewUser) {
        try {
          await this.create();
          await this.setCustomClaimsForUser({ startup_investor: true });
        } catch (firestoreError) {
          // If Firestore creation fails, delete the user from Firebase Auth
          await deleteUser(userCredential.user);
          throw new Error(`Failed to create user in Firestore: ${firestoreError.message}`);
        }
      }

      return this.data;
    } catch (error) {
      throw new Error(`Google sign-in failed: ${error.message}`);
    }
  }

  async setCustomClaimsForUser(roles) {
    try {
      const setCustomClaims = httpsCallable(functions, 'setCustomClaims');
      const result = await setCustomClaims({ uid: this.data.uid, roles });
      return result.data;
    } catch (error) {
      throw new Error(`Setting custom claims failed: ${error.message}`);
    }
  }
}

export default UserAuthModel;