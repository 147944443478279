import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../ThemeContext';

const Footer = () => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);

  return (
    <footer
      className="w-full py-4 mt-auto"
      style={{
        backgroundColor: theme.appBackgroundColor,
        color: theme.textColor
      }}
    >
      <div className="text-center">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          {t('footer.copyright')}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
