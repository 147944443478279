import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Label
} from 'recharts';

const ResultCard = ({ title, children, className = '' }) => (
  <div className={`bg-gray-700 rounded-lg p-4 ${className}`}>
    {title && <h3 className="font-medium mb-2">{title}</h3>}
    {children}
  </div>
);

const formatCurrency = (amount) => {
  if (!amount && amount !== 0) return '$0';
  return `$${amount.toLocaleString()}`;
};

const Results = ({ journey, formData }) => {
  const { t } = useTranslation(['financingJourney']);

  // Safely get the opportunity cost
  const opportunityCost = formData?.opportunityCost ? parseInt(formData.opportunityCost) : 0;

  // Calculate the total potential return (exit value)
  const exitValue = journey?.exitCalculation?.minimumExitValue || 0;
  
  // Get the catch-up year
  const catchupYear = journey?.exitCalculation?.catchupYear || 0;

  // Calculate annual return
  const timeHorizonYears = journey?.exitCalculation?.timeHorizonYears || 5;
  const annualReturn = (exitValue / timeHorizonYears) || 0;

  // Calculate multiplier
  const multiplier = opportunityCost > 0 ? (annualReturn / opportunityCost).toFixed(1) : 0;

  // Check margin of safety
  const hasMarginOfSafety = annualReturn >= (2 * opportunityCost);

  // Prepare chart data
  const opportunityCostData = [
    {
      name: t('results.charts.opportunityCost'),
      'Current Income': opportunityCost,
      'Potential Return': annualReturn
    }
  ];

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold mb-4">{t('results.title')}</h2>
      
      <ResultCard>
        <p className={`text-lg ${hasMarginOfSafety ? 'text-green-400' : 'text-yellow-400'}`}>
          {t(`results.marginOfSafety.${hasMarginOfSafety ? 'good' : 'warning'}`)}
        </p>
      </ResultCard>

      <ResultCard title={t('results.charts.opportunityCost')} className="h-80">
        <div className="relative h-full">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart 
              data={opportunityCostData}
              margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
            >
              <XAxis dataKey="name" />
              <YAxis tickFormatter={(value) => `$${value / 1000}k`} />
              <Tooltip 
                formatter={(value) => formatCurrency(value)}
                labelStyle={{ color: '#374151' }}
                contentStyle={{ backgroundColor: '#1F2937', border: 'none' }}
              />
              <Legend />
              <Bar 
                dataKey="Current Income" 
                fill="#6B7280" // Secondary color (gray-500)
                radius={[4, 4, 0, 0]}
              />
              <Bar 
                dataKey="Potential Return" 
                fill="#10B981" // Primary color (emerald-500)
                radius={[4, 4, 0, 0]}
              />
              {/* Multiplier Label */}
              <Label
                content={({ viewBox: { x, y, width } }) => (
                  <text
                    x={x + width / 2}
                    y={y - 5}
                    textAnchor="middle"
                    className="text-lg font-bold fill-current"
                  >
                    {`${multiplier}x`}
                  </text>
                )}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </ResultCard>

      <ResultCard title={t('financingPath.title')}>
        {journey?.financingPath?.map((stage, index) => (
          <div key={index} className="mb-4 last:mb-0">
            <div className="flex justify-between items-center mb-1">
              <h4 className="font-medium">{t(`financingPath.stages.${stage.stage}`)}</h4>
              <span className="text-sm text-gray-300">
                {t('financingPath.timing', { month: stage.timing })}
              </span>
            </div>
            <p className="text-sm">
              {t('financingPath.valuation', { 
                amount: formatCurrency(stage.minimumValuation) 
              })}
            </p>
            {stage.minimumRaise > 0 && (
              <p className="text-sm text-gray-400">
                {t('financingPath.raise', { 
                  amount: formatCurrency(stage.minimumRaise),
                  dilution: (stage.expectedDilution * 100).toFixed(0)
                })}
              </p>
            )}
          </div>
        ))}
      </ResultCard>

      <ResultCard>
        <p>{t('results.projectedReturn', {
          multiple: formData.growthGoal === 'moonshot' ? '100' :
                   formData.growthGoal === 'big' ? '10' :
                   formData.growthGoal === 'solid' ? '5' : '2',
          amount: formatCurrency(annualReturn)
        })}</p>
        <p className="mt-2">{t('results.timeToReturns', {
          years: timeHorizonYears,
          year: catchupYear
        })}</p>
        <p className="mt-2">{t('ownershipStake.description', {
          percentage: formData.ownershipStake
        })}</p>
        <p className="mt-2 text-gray-400">{t('results.investorRelationship')}</p>
      </ResultCard>
    </div>
  );
};

export default Results;
