import React, { useContext, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../ThemeContext';
import CurrentLeadInvestors from './CurrentLeadInvestors';
import PilotLaunchRoadmap from './PilotLaunchRoadmap';

const WhatsNextSection = () => {
  const { t } = useTranslation('businessDev');
  const { theme } = useContext(ThemeContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const titleRef = useRef(null);

  useEffect(() => {
    if (isExpanded && titleRef.current) {
      const elementPosition = titleRef.current.getBoundingClientRect().top;
      const offsetPosition = window.scrollY + elementPosition - 46;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }, [isExpanded]);

  const handleToggle = () => setIsExpanded(!isExpanded);

  return (
    <div className="space-y-8 md:space-y-12">
      {/* Title with human-style underline and bouncing arrow */}
      <div className="pb-4 flex flex-col items-center md:items-start">
        <button
          onClick={handleToggle}
          className="text-2xl md:text-4xl font-bold w-full text-center md:text-left"
          style={{ color: theme.textColor }}
        >
          <span className="relative inline-block">
            {t('whatsNext.title')}
            <svg
              className="absolute -bottom-1 left-0 w-full"
              viewBox="0 0 200 24"
              preserveAspectRatio="none"
              style={{ height: '0.6rem' }}
            >
              <path
                d="M3,12 Q50,16 100,12 T197,12"
                fill="none"
                stroke={theme.primaryColor}
                strokeWidth="6"
                strokeLinecap="round"
                transform="rotate(-0.5)"
                transformOrigin="center"
              />
            </svg>
          </span>
        </button>
        
        {/* Modern bouncing down arrow - now clickable */}
        {!isExpanded && (
          <button
            onClick={handleToggle}
            className="mt-4 focus:outline-none hover:opacity-80 transition-opacity"
            aria-label="Expand section"
          >
            <svg
              className="w-12 h-12 bounce-animation"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 4L12 20M12 20L18 14M12 20L6 14"
                stroke={theme.primaryColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
      </div>

      {/* Expandable content */}
      {isExpanded && (
        <div
          style={{
            animation: 'slideDown 0.5s ease-out'
          }}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
            <PilotLaunchRoadmap ref={titleRef} />
            <CurrentLeadInvestors />
          </div>
        </div>
      )}

      {/* CSS animations */}
      <style jsx>{`
        @keyframes slideDown {
          from {
            opacity: 0;
            transform: translateY(-20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes bounce {
          0%, 100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-10px);
          }
        }

        .bounce-animation {
          animation: bounce 2s infinite ease-in-out;
        }
      `}</style>
    </div>
  );
};

export default WhatsNextSection;
