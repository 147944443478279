import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../ThemeContext';

const stageIcons = {
  ideation: '💡',
  marketTested: '🎯',
  upAndRunning: '📈',
  growthReady: '🚀',
  default: '💡'
};

const ProgressBar = ({ currentStep, totalSteps, currentStage }) => {
  const { t } = useTranslation(['financingJourney']);
  const { theme } = useContext(ThemeContext);
  const progress = ((currentStep - 1) / (totalSteps - 1)) * 100;
  
  const icon = currentStage ? stageIcons[currentStage] : stageIcons.default;

  return (
    <div className="mb-8">
      <div className="flex justify-between items-center mb-2">
        <span className="text-sm text-gray-400">
          {t('progress.step', { current: currentStep, total: totalSteps })}
        </span>
        <span className="text-sm text-gray-400">{Math.round(progress)}%</span>
      </div>
      <div className="relative">
        <div className="w-full bg-gray-700 rounded-full h-2.5">
          <div
            style={{ 
              width: `${progress}%`,
              height: '0.625rem',
              backgroundColor: theme.primaryColor,
              borderRadius: '9999px',
              transition: 'all 300ms ease-in-out'
            }}
          />
        </div>
        {/* Icon pointer */}
        <div 
          className="absolute top-1/2 transform -translate-y-1/2 transition-all duration-300 ease-in-out"
          style={{ 
            left: `${progress}%`, 
            transform: 'translate(-50%, -50%)',
            fontSize: '1.5rem'
          }}
        >
          {icon}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
