import React, { useContext } from 'react';
import { ThemeContext } from '../../../ThemeContext';

const SelectQuestion = ({ title, options, value, onChange, getOptionLabel = (option) => option }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold mb-4">{title}</h2>
      <div className="grid gap-4">
        {options.map((option) => (
          <button
            key={option}
            onClick={() => onChange(option)}
            className={`p-4 rounded-lg border transition-colors duration-200 ${
              value === option
                ? 'border-primary bg-opacity-10'
                : 'border-gray-700 hover:border-gray-600'
            }`}
            style={{
              borderColor: value === option ? theme.primaryColor : undefined,
              backgroundColor: value === option ? `${theme.primaryColor}1a` : undefined
            }}
          >
            {getOptionLabel(option)}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SelectQuestion;
