import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../ThemeContext';
import { useAuth } from '../AuthContext';
import { Button } from '../components/ui/Button';
import { User, Mail, Calendar, Clock, Globe, Shield, TrendingUp, Target, Briefcase, DollarSign, CreditCard, FileText, ArrowUpDown } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import UserModel from '../models/user';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const ProfileItem = ({ icon: Icon, label, value, action, onAction, isEditing, onEdit, onSave, onCancel }) => {
  const { theme } = useContext(ThemeContext);
  const [editValue, setEditValue] = useState(value);

  return (
    <div className="flex items-center space-x-4 p-4 bg-gray-700 rounded-lg">
      <Icon className="w-6 h-6" style={{ color: theme.primaryColor }} />
      <div className="flex-grow">
        <p className="text-sm text-gray-400">{label}</p>
        {isEditing ? (
          <input
            type="text"
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            className="text-lg font-semibold bg-gray-600 text-white rounded px-2 py-1 w-full"
          />
        ) : (
          <p className="text-lg font-semibold">{value}</p>
        )}
      </div>
      {action && !isEditing && (
        <Button onClick={onEdit} variant="ghost" size="sm">
          {action}
        </Button>
      )}
      {isEditing && (
        <>
          <Button onClick={() => onSave(editValue)} variant="ghost" size="sm">
            Save
          </Button>
          <Button onClick={onCancel} variant="ghost" size="sm">
            Cancel
          </Button>
        </>
      )}
    </div>
  );
};

const ToggleSwitch = ({ label, checked, onChange }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <label className="flex items-center cursor-pointer">
      <div className="relative">
        <input type="checkbox" className="sr-only" checked={checked} onChange={onChange} />
        <div className={`block w-14 h-8 rounded-full ${checked ? '' : 'bg-gray-600'}`} style={{ backgroundColor: checked ? theme.primaryColor : '' }}></div>
        <div className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${checked ? 'transform translate-x-6' : ''}`}></div>
      </div>
      <div className="ml-3 text-gray-300 font-medium">
        {label}
      </div>
    </label>
  );
};

const ProfilePage = () => {
  const { theme } = useContext(ThemeContext);
  const { user } = useAuth();
  const [userProfile, setUserProfile] = useState(null);
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const { t, i18n } = useTranslation(['pages', 'common']);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user) {
        try {
          const userModel = new UserModel(user.uid);
          const profile = await userModel.get();
          setUserProfile(profile);
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      }
    };

    fetchUserProfile();
  }, [user]);

  useEffect(() => {
    dayjs.locale(i18n.language);
  }, [i18n.language]);

  if (!userProfile) {
    return <div className="flex items-center justify-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>;
  }

  const handleAction = (action) => {
    // Implement action functionality
    console.log(`Performing action: ${action}`);
  };

  const handleToggle2FA = () => {
    setTwoFactorEnabled(!twoFactorEnabled);
    // Implement 2FA toggle functionality
  };

  const handleEditName = () => {
    setIsEditingName(true);
  };

  const handleSaveName = async (newName) => {
    if (user) {
      try {
        const userModel = new UserModel(user.uid);
        const updatedProfile = {
          ...userProfile,
          displayName: newName
        };
        const result = await userModel.update(updatedProfile);
        setUserProfile(result);
        setIsEditingName(false);
      } catch (error) {
        console.error('Error updating user name:', error);
        // Handle error (e.g., show error message to user)
      }
    }
  };

  const handleCancelEditName = () => {
    setIsEditingName(false);
  };

  const formatDate = (dateString) => {
    if (!dateString) return t('pages:profile.notAvailable');
    return dayjs(dateString).format('MMM D');
  };

  const formatTimeAgo = (dateString) => {
    if (!dateString) return t('pages:profile.notAvailable');
    return dayjs(dateString).fromNow();
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: 'CAD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };

  return (
    <div className="min-h-screen p-4 md:p-8" style={{ backgroundColor: theme.appBackgroundColor, color: theme.textColor }}>
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold mb-8">{t('pages:profile.title')}</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-4">
            <h2 className="text-2xl font-bold mb-4">{t('pages:profile.personalInformation')}</h2>
            <ProfileItem icon={Mail} label={t('pages:profile.email')} value={userProfile.email || t('pages:profile.notAvailable')} />
            <ProfileItem
              icon={User}
              label={t('pages:profile.name')}
              value={userProfile.displayName || t('pages:profile.notSet')}
              action={t('pages:profile.edit')}
              onEdit={handleEditName}
              isEditing={isEditingName}
              onSave={handleSaveName}
              onCancel={handleCancelEditName}
            />
          </div>

          <div className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-4">
            <h2 className="text-2xl font-bold mb-4">{t('pages:profile.investmentPreferences')}</h2>
            <ProfileItem icon={TrendingUp} label={t('pages:profile.riskTolerance')} value={t('pages:profile.moderate')} action={t('pages:profile.edit')} onAction={() => handleAction('editRiskTolerance')} />
            <ProfileItem icon={Target} label={t('pages:profile.investmentGoals')} value={t('pages:profile.longTermGrowth')} action={t('pages:profile.edit')} onAction={() => handleAction('editInvestmentGoals')} />
            <ProfileItem icon={Briefcase} label={t('pages:profile.preferredIndustries')} value={t('pages:profile.preferredIndustriesValue')} action={t('pages:profile.edit')} onAction={() => handleAction('editPreferredIndustries')} />
          </div>

          <div className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-4">
            <h2 className="text-2xl font-bold mb-4">{t('pages:profile.financialInformationTransactions')}</h2>
            <ProfileItem icon={DollarSign} label={t('pages:profile.yearToDateInvestmentAmount')} value={formatCurrency(5000)} />
            <ProfileItem icon={TrendingUp} label={t('pages:profile.yearlyInvestmentLimit')} value={formatCurrency(10000)} />
            <ProfileItem icon={CreditCard} label={t('pages:profile.bankAccount')} value={t('pages:profile.maskedAccountNumber', { lastFour: '1234' })} action={t('pages:profile.manageBankAccount')} onAction={() => handleAction('manageBankAccount')} />
            <ProfileItem icon={FileText} label={t('pages:profile.investmentsHistory')} value="" action={t('pages:profile.seeHistory')} onAction={() => handleAction('seeInvestmentsHistory')} />
            <ProfileItem icon={ArrowUpDown} label={t('pages:profile.pendingTransfers')} value="" action={t('pages:profile.seePending')} onAction={() => handleAction('seePendingTransfers')} />
          </div>

          <div className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-4">
            <h2 className="text-2xl font-bold mb-4">{t('pages:profile.security')}</h2>
            <ProfileItem icon={Calendar} label={t('pages:profile.firstSignIn')} value={formatDate(userProfile.metadata?.creationTime)} />
            <ProfileItem icon={Clock} label={t('pages:profile.lastSignIn')} value={formatTimeAgo(userProfile.metadata?.lastSignInTime)} />
            <ProfileItem icon={Shield} label={t('pages:profile.password')} value={t('pages:profile.passwordPlaceholder')} action={t('pages:profile.change')} onAction={() => handleAction('changePassword')} />
            <div className="mt-4">
              <ToggleSwitch label={t('pages:profile.twoFactorAuth')} checked={twoFactorEnabled} onChange={handleToggle2FA} />
            </div>
          </div>

          <div className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-4">
            <h2 className="text-2xl font-bold mb-4">{t('pages:profile.generalPreferences')}</h2>
            <ProfileItem icon={Globe} label={t('pages:profile.language')} value={t('pages:profile.english')} action={t('pages:profile.edit')} onAction={() => handleAction('editLanguage')} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
